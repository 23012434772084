import React, { FC } from 'react';

import TUserField from './TUserField';

const TUserLink = props =>
    props.record ? (
            <TUserField {...props} />
    ) : null;

    TUserLink.defaultProps = {
    source: 'user_name',
    addLabel: true,
};

export default TUserLink;
