import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';

const styles = {
  truncate: {
    overflow: "hidden",
  },
  avatarSize:{
    height:'40px',
    width:'40px'
  }
};

const EventItemView = ({ event, classes }) => (
  <ListItem className="jss298">
    <ListItemAvatar>
      <Avatar />
    </ListItemAvatar>
    <ListItemText
      primary={
        <div className={classes.truncate} style={{fontSize:'0.875rem'}}>
          <strong>{event.author ? event.author : "Anonymous"}</strong>{" "}
          {event.action_title}
        </div>
      }
      secondary={new Date(event.date).toLocaleString()}
    />
  </ListItem>
);

const EventItem = withStyles(styles)(EventItemView);

export default EventItem;
