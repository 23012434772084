import React, { FC } from 'react';
import { Link } from 'react-admin';

import BlockStatusField from './BlockStatusField';

const CustomStatusField = props =>
    props.record ? (
            <BlockStatusField {...props} />
    ) : null;

    CustomStatusField.defaultProps = {
    source: 'block_status',
    addLabel: true,
};

export default CustomStatusField;
