import React, { FC } from 'react';
import { Link } from 'react-admin';

import DeviceField from './DeviceField';

const CustomDeviceField = props =>
    props.record ? (
            <DeviceField {...props} />
    ) : null;

    CustomDeviceField.defaultProps = {
    source: 'device',
    addLabel: true,
};

export default CustomDeviceField;
