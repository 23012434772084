import * as React from "react";
import {
  useState,
  useEffect,
  useCallback,
  CSSProperties,
  ReactElement,
  FC,
  createElement,
} from "react";
import {
  Card,
  Box,
  Typography,
  Divider,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import EventList from "./EventListDashboard";
import PendingOrders from "./PendingOrders";
import NewCustomers from "./NewCustomers";
import CompletedOrders from "./CompletedOrders";
import api_url from "./ApiUrl";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import JWT from "jsonwebtoken";

import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { useTranslate } from "react-admin";
import { format, subDays, addDays } from "date-fns";

import cartouche from "./cartouche.png";
import cartoucheDark from "./cartoucheDark.png";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
//import ReviewsIcon from '@material-ui/icons/Reviews';
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import MoneyOffOutlinedIcon from "@material-ui/icons/MoneyOffOutlined";
//import ReviewsIcon from '@mui/icons-material/Reviews';

const Spacer = () => <span style={{ width: "1em" }} />;
const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  main: {
    overflow: "inherit",
    padding: 16,
    background: `url(${theme.palette.type === "dark" ? cartoucheDark : cartouche
      }) no-repeat`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
    },
  },
  title: {fontSize:'0.875rem',fontWeight:'500'},
}));

const styles = {
  flex: { display: "flex", marginTop: "5px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { marginLeft: "0.5em", maxWidth: "30em", width: "30%" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  margintop: { marginTop: "5px" },
};

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);
const dateFormatter = (date) => new Date(date).toLocaleDateString();
const Dashboard = (props) => {
  const [dashboardData, setDashboardData] = useState({});
  useEffect(() => {
    getGitDashboardLogWithFetch();
  }, []);
  const getGitDashboardLogWithFetch = async () => {
    let login_token = localStorage.getItem("auth");
    let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
      algorithm: "HS512",
    });
    const request = new Request(api_url() + "/dashboard_records", {
      method: "POST",
      body: JSON.stringify({ getdashboarddata: "OK" }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${login_token}`,
        'xelxtoken': apitoken
      }),
    });
    const response = await fetch(request);
    const jsonData = await response.json();
    setDashboardData(jsonData);
  };

  //console.log(getRevenuePerDay());
  //const { icon, title, subtitle, to, children } = props;
  const classes = useStyles();
  return (
    <Grid container>
      <Grid xs={12} item>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <Card className={classes.card}>
                <div className={classes.main}>
                  <Box width="3em" className="icon">
                    <AttachMoneyIcon />
                  </Box>
                  <Box textAlign="right">
                    <Typography className={classes.title} color="textSecondary">
                      {"Monthly revenue"}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ fontSize: "0.875rem" }}
                    >
                      ${dashboardData.total_revenue}
                    </Typography>
                  </Box>
                </div>
              </Card>
              <Spacer />
              <Card className={classes.card}>
                <div className={classes.main}>
                  <Box width="3em" className="icon">
                    <MoreHorizOutlinedIcon />
                  </Box>
                  <Box textAlign="right">
                    <Typography className={classes.title} color="textSecondary">
                      {"Waiting orders"}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ fontSize: "0.875rem" }}
                    >
                      <Link to={`orders?filter={"type":"waiting"}`}>{dashboardData.waiting_orders}</Link>
                    </Typography>
                  </Box>
                </div>
              </Card>

              <Spacer />
              <Card className={classes.card}>
                <div className={classes.main}>
                  <Box width="3em" className="icon">
                    <SyncOutlinedIcon />
                  </Box>
                  <Box textAlign="right">
                    <Typography className={classes.title} color="textSecondary">
                      {"Processing orders"}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ fontSize: "0.875rem" }}
                    >
                      <Link to={`orders?filter={"type":"processing"}`}>{dashboardData.processing_order}</Link>
                    </Typography>
                  </Box>
                </div>
              </Card>

              <Spacer />
              <Card className={classes.card}>
                <div className={classes.main}>
                  <Box width="3em" className="icon">
                    <MoneyOffOutlinedIcon />
                  </Box>
                  <Box textAlign="right">
                    <Typography className={classes.title} color="textSecondary">
                      {"Hold orders"}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ fontSize: "0.875rem" }}
                    >
                      <Link to={`orders?filter={"type":"hold"}`}>{dashboardData.total_hold_orders}</Link>
                    </Typography>
                  </Box>
                </div>
              </Card>
            </div>

            <div style={styles.singleCol}>
              <Card>
                <CardHeader
                  title="30 day revenue history"
                  className="dashboard-title-sizes"
                />
                <CardContent>
                  <div style={{ width: "100%", height: 300 }}>
                    <ResponsiveContainer>
                      <AreaChart data={dashboardData.graph_data}>
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#8884d8"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="#8884d8"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis
                          dataKey="date"
                          name="Date"
                          type="number"
                          scale="time"
                          domain={[
                            addDays(aMonthAgo, 1).getTime(),
                            new Date().getTime(),
                          ]}
                          tickFormatter={dateFormatter}
                        />
                        <YAxis dataKey="total" name="Revenue" unit="$" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip
                          cursor={{ strokeDasharray: "3 3" }}
                          formatter={(value) =>
                            new Intl.NumberFormat(undefined, {
                              style: "currency",
                              currency: "USD",
                            }).format(value)
                          }
                          labelFormatter={(label) => dateFormatter(label)}
                        />
                        <Area
                          type="monotone"
                          dataKey="total"
                          stroke="#8884d8"
                          strokeWidth={2}
                          fill="url(#colorUv)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div style={styles.flex}>
              <PendingOrders orders={dashboardData.waiting_order_lists} />
              <Spacer />
              <CompletedOrders orders={dashboardData.completed_order_lists} />
              <Spacer />
              <NewCustomers customers={dashboardData.new_customer_lists} />
            </div>
          </div>
          <div style={styles.rightCol}>
            <EventList events={dashboardData.event_records} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
