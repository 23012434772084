import React, { FC } from 'react';
import BrowserField from './BrowserField';

const CustomBrowserField = props =>
    props.record ? (
            <BrowserField {...props} />
    ) : null;

    CustomBrowserField.defaultProps = {
    source: 'browser',
    addLabel: true,
};

export default CustomBrowserField;
