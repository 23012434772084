import React, { FC } from 'react';

import OdLinkField from './OdLinkField';

const OdLink = props =>
    props.record ? (
            <OdLinkField {...props} />
    ) : null;

    OdLink.defaultProps = {
    source: 'transaction_id',
    addLabel: true,
};

export default OdLink;
