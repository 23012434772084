import * as React from "react";
import Switch from '@material-ui/core/Switch';
import {
    Edit,
    SelectInput,
    FormWithRedirect,
    TextInput,
    SaveButton,
    DeleteButton,
    Link,
    Create,
    SimpleForm,
    useNotify,
    useRedirect,
    Toolbar,
    // LongTextInput,
    useWarnWhenUnsavedChanges,
    BooleanInput,
    ImageInput,
    ImageField
} from 'react-admin';
import { Box, Card, CardContent, Typography, Grid, Button, Hidden, InputLabel } from '@material-ui/core';
import api_url from './ApiUrl';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { makeStyles, darken, fade, lighten } from '@material-ui/core/styles';

const options = {
    previewStyle: "tab",
    height: "300px",
    initialEditType: "wysiwyg",
    useCommandShortcut: false,
};

const validateNewsData = (values) => {
    const errors = {};
    if (!values.titles) {
        errors.titles = 'The title is required';
    }

    if (!values.details) {
        errors.details = 'The details is required';
    }

    if (!values.meta_title) {
        errors.meta_title = 'The meta title is required';
    }

    if (!values.meta_keyword) {
        errors.meta_keyword = 'The meta keyword is required';
    }

    if (!values.meta_description) {
        errors.meta_description = 'The meta description is required';
    }

    return errors
};

const useStyles = makeStyles(theme => ({
    inputHeight: { height: '50px !important' },
    fontBold: { fontWeight: '400', textTransform: 'capitalize', fontSize: '15px', color: '#80a3b6' },
    marginTop15: { marginTop: '15px', marginBottom: '15px' },
    marginTop10: { marginTop: '10px' },
    marginTop20: { marginTop: '20px' },
    marginBottom20: { marginBottom: '20px' },
    paddingLeft15: { paddingLeft: '15px' },
    paddingRight15: { paddingRight: '15px' },
    rightAlignedCell: { textAlign: 'right' },
    marginBottom10: { marginBottom: '10px' },
    borderbottom0: { borderBottom: '0', textAlign: 'right' },
    statusIconSet: { display: 'flex', position: 'absolute', height: '18px', right: '375px' },
    hrline: { borderBottom: '1px solid rgba(128, 163, 182, 0.1)', marginBottom: '15px' },
    headerText: { fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, marginBottom: '0' },
    headerText2: { fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, marginBottom: '0', textAlign: 'right' },
    generalText: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 400 },
    generalText2: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 400, textAlign: 'right' },
    generalText3: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, textAlign: 'right', borderBottom: '0' },
    generalText4: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, textAlign: 'right', borderBottom: '0', textTransform: 'capitalize' },
    root: {
        width: '96%',
        margin: '0 auto',
        borderStyle: 'solid',
        borderWidth: 1,
        marginTop: '2rem',
        marginBottom: '2rem',
        borderColor:
            theme.palette.type === 'light'
                ? lighten(fade(theme.palette.divider, 1), 0.88)
                : darken(fade(theme.palette.divider, 1), 0.68),
    },
    spacer: { height: 20 },
    invoices: { margin: '10px 0' },
}));

const AddNewsForm = (props) => {
    const classes = useStyles();
    //useWarnWhenUnsavedChanges(true);
    const [checked, setChecked] = React.useState(true);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    }
    return (
        <>
            <Grid container>
                <Grid item xs={8}>
                    <Box flex={1} mr="1em" style={{ borderRight: '1px dashed #ccc' }}>
                        <Grid container className="topinputfield">
                            <Grid item xs={12}>
                                <Box mr="1em">
                                    <TextInput source="titles" variant="outlined" label="Post Title" fullWidth />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mr="1em" className="newmarkdown">
                                    <MarkdownInput variant="outlined" source="details" resource="news" options={options} label="Content" fullWidth />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mr="1em" className="newmarkdown">
                                    <ImageInput source="pictures" label="Cover">
                                        <ImageField source="photo" title="Cover photo" />
                                    </ImageInput>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={4} className="topinputfield">
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item sm={10}>
                                <Box pt={2}>
                                    <InputLabel className={classes.fontWeightSize}>Publish</InputLabel>
                                </Box>
                            </Grid>
                            <Grid item sm={2}>
                                <Box pt={1} style={{ float: 'right' }}>
                                    {/* <Switch
                                        checked={checked}
                                        onClick={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    /> */}
                                    <BooleanInput label="" source="status" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mr="1em">
                            <TextInput source="meta_title" variant="outlined" resource="news" label="Meta title" fullWidth className="customInputHeight" />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mr="1em">
                            <TextInput multiline source="meta_description" variant="outlined" resource="news" label="Meta description" fullWidth className="customInputHeight" />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mr="1em">
                            <TextInput source="meta_keyword" variant="outlined" resource="news" label="Meta keywords" fullWidth className="customInputHeight" />
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
};
const NewsAddActions = (props) => (
    <ListActions  {...props} hasCreate={true} exporter={false} />
)

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Post"
            redirect="show"
            submitOnEnter={true}
            disabled={props.pristine}
        />
        <Link to={'/news?filter={"type":"news"}'}><Button variant="outlined" color="default" style={{ marginLeft: '15px' }}>Cancel</Button></Link>
    </Toolbar>
);
const AddNews = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('News added successfully');
        redirect('/news?filter={"type":"news"}');
    }
    const onFailure = (error) => {
        if (error.code == 429) {
            notify('Could not save:Invalid data provided', 'warning');
        } else {
            notify('ra.notification.http_error', 'warning')
        }
        redirect('/news?filter={"type":"news"}');
    }
    return (
        <>
            <Create
                {...props}
                actions={<NewsAddActions />}
                onSuccess={onSuccess}
                onFailure={onFailure}>
                <SimpleForm toolbar={<CustomToolbar />} validate={false}>
                    <AddNewsForm />
                </SimpleForm>
            </Create>
        </>
    )
};
export default AddNews;