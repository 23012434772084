import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { FieldProps, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    main_segments: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
        float:'left',    
        justifyContent:'flex-start'    
    },
    chip: { margin: 4 },
});

const segments = [
    { id: 'ordered_once', name: 'Ordered once' },
    { id: 'regular', name: 'Regular' },
    { id: 'returns', name: 'Returns' },
    { id: 'api_users', name: 'API users' },
    { id: 'referrer', name: 'Referrer' },
    { id: 'reviewers', name: 'Reviewers' },
    { id: 'hacker', name: 'Hacker' },
    { id: 'merchant', name: 'Merchant' },
    { id: 'fraudsters', name: 'Fraudsters' }
];

const SegmentsField = ({record}) => {
    const translate = useTranslate();
    const classes = useStyles();
    return record ? (
        <span className={`${classes.main_segments} segment_main_class`}>
            {record.groups &&
                record.groups.map(segmentId => {
                    const segment = segments.find(s => s.id === segmentId);
                    return segment ? (
                        <Chip
                            key={segment.id}
                            className={classes.chip}
                            label={translate(segment.name)}                            
                        />
                    ) : null;
                })}
        </span>
    ) : null;
};

SegmentsField.defaultProps = {
    addLabel: true,
    label:'Segments',
    source: 'Segments',
};

export default SegmentsField;
