import React, { FC } from 'react';
import { Fragment } from 'react';
import {
    DateField,
    DateInput,
    TextField,
    BulkDeleteButton,
    BulkExportButton,
    TextInput,
    SelectInput
} from 'react-admin';

import { List, ListActions } from '@react-admin/ra-enterprise';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import { CustomBreadcrumb } from './CustomBreadcrumb';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            return_value = 'ok';
        }
    });
    return return_value;
}

const ReferralCommissionBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('withdrawfees', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('withdrawfees', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const WithdrawForm = props => (
    <RowForm {...props}>
        <TextField source="withdraw_type" />
        <TextInput source="range_from_alter" autoFocus label="Min amount" />
        <TextInput source="range_to_alter" autoFocus label="Max amount" />
        <TextInput source="change_fee_alter" />
        {/* <SelectInput source="charge_type" choices={withdrawType_choices} label="Fee type"/> */}
        <DateInput source="created_at" />
    </RowForm>
);

const WithdrawListActions = props => (
    <ListActions
        {...props}
        breadcrumb={<CustomBreadcrumb variant="actions" />}
        exporter={(idRoleAllow('referrals', 'export') == 'ok') ? true : false}
    />
);

const withdrawType_choices = [
    { id: 'percentage', name: 'Percentage' },
    { id: 'fixed', name: 'Fixed' }];

const WithdrawFeesList = (props) => (
    <List
        {...props}
        sort={{ field: 'change_fee', order: 'ASC' }}
        actions={<WithdrawListActions />}
        empty={false}
        perPage={25}
        title="Withdraw fees"
        bulkActionButtons={<ReferralCommissionBulkActionButtons />}
    >
        <EditableDatagrid
            data-testid="store-datagrid"
            rowClick="edit"
            createForm={<WithdrawForm />}
            editForm={<WithdrawForm />}
        >
            <TextField source="withdraw_type" label="Currency" />
            <TextField source="range_from" label="Min amount" />
            <TextField source="range_to" label="Max amount" />
            <TextField source="change_fee" label="Fees" />
            {/* <TextField source="charge_type" label="Fees type" /> */}
            <DateField source="created_at" label="Created at" showTime />
        </EditableDatagrid>
    </List>
);
export default WithdrawFeesList;
