import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
    BooleanInput,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import TransactionIdLink from './TransactionIdLink';
import TUserLink from './TUserLink';
import WithdrawStatus from './WithdrawStatus';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const WithdrawalCommissionBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('canceledcommission', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('canceledcommission', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const WithdrawalCommissionActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('completedcommission', 'export') == 'ok') ? true : false} />
)

const WithdrawalCommissionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Transaction Id" source="withdraw_id" defaultValue="" />
        <TextInput label="User name" source="user_name" defaultValue="" />
        <DateInput label="Canceled since" source="created_at" defaultValue="" />
        <DateInput label="Canceled before" source="updated_at" defaultValue="" />
    </Filter>
);

const newStyle = makeStyles({
    tdcolor: { color: '#4f3cc9' }
});

const CanceledCommission = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'updated_at', order: 'DESC' }}
            actions={<WithdrawalCommissionActions />}
            filters={<WithdrawalCommissionFilter />}
            bulkActionButtons={<WithdrawalCommissionBulkActionButtons />}
            pagination={<CustomPagination />}
            title="Canceled withdraw"            
        >
            <Datagrid>
                <TransactionIdLink source="withdraw_id" label="Transaction Id" />
                <TUserLink source="user_name" label="User name" />
                <TextField source="withdraw_currency" label="Currency name" />
                <TextField source="withdraw_amount" label="Withdrawn" />
                <TextField source="withdraw_fee" label="Fee" />
                <TextField source="received_amount" label="Received" />
                <DateField source="updated_at" label="Canceled at" showTime />
                <WithdrawStatus source="status" label="Status" />
            </Datagrid>
        </List>
    )
};
export default CanceledCommission;