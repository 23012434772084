import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    //EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    ImageField,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import CustomerLinkFieldEvents from './CustomerLinkFieldEvents';
import CustomOSField from './CustomOSField';
import CustomBrowserField from './CustomBrowserField';
import CustomCountryField from './CustomCountryField';
import CustomDeviceField from './CustomDeviceField';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            return_value = 'ok';
        }
    });
    return return_value;
}

const PostBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('locked', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('locked', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const LockListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('locked', 'export') == 'ok') ? true : false} />
)

const LockFilter = (props) => (
    <Filter {...props}>
        <TextInput label="User IP" source="bip" defaultValue="" />
        <TextInput label="User email" source="user_email" defaultValue="" />
        <DateInput label="Locked since" source="created_date" defaultValue="" />
        <DateInput label="Locked before" source="release_time" defaultValue="" />
    </Filter>
);

const LockLists = (props) => {
    return (
        <List
            {...props}
            title="Locked"
            sort={{ field: 'bip', order: 'ASC' }}
            actions={<LockListActions />}
            filters={<LockFilter />}
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<CustomPagination />}
        >
            <Datagrid className="lastOrdersColumn">
                <TextField source="sn" label="SN" />
                <CustomerLinkFieldEvents source="author" label="User name" />
                <CustomCountryField source="country" label="Country" className='tableimages' />
                <CustomDeviceField source="device" label="Device" />
                <CustomOSField source="os" label="OS" className='tableimages' />
                <CustomBrowserField source="browser" height='16' label="Browser" className='tableimages' />
                <TextField source="topics" label="Topics" />
                <DateField source="created_date" label="Locked at" showTime />
                {/* <TextField source="release_time" label="Released at" /> */}
            </Datagrid>
        </List>
    )
};
export default LockLists;