import React, { FC } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    DateField,
    DateInput,
    TextField,
    TextInput,
    ListProps,
} from 'react-admin';

import { List, ListActions } from '@react-admin/ra-enterprise';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { makeStyles } from '@material-ui/core/styles';
import { MarkdownInput } from '@react-admin/ra-markdown';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const TermsForm = props => (
    <RowForm {...props}>
        <TextField source="page_title" label="Title" />
        <MarkdownInput source="page_content" autoFocus label="" />
    </RowForm>
);

const useListStyles = makeStyles({
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const TermsActions = props => (
    <ListActions
        {...props}
        exporter={false}
        breadcrumb={<CustomBreadcrumb variant="actions" />}
    />
);

const TermsDataList = (props) => {
    const classes = useListStyles();
    return (
        <List
            {...props}            
            actions={<TermsActions />}
            empty={false}            
            pagination={false}
            bulkActionButtons={false}
            title="Terms of use"
            //exporter={(idRoleAllow('termsofuse', 'export') == 'ok') ? true : false}
        >
            <EditableDatagrid
                data-testid="store-datagrid"
                rowClick="edit"
                createForm={<TermsForm />}
                editForm={<TermsForm />}
            >
                <TextField source="page_title" label="Title" />
                <TextField source="page_content" label="Content" cellClassName={classes.comment} />
            </EditableDatagrid>
        </List>
    )
};
export default TermsDataList;
