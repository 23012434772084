import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            //console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}
const PostBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('withdraws', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('withdraws', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const WithdrawListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('withdraws', 'export') == 'ok') ? true : false} />
)

const WithdrawFilter = (props) => (
    <Filter {...props}>
        <TextInput label="User Id" source="user_id" defaultValue="" /> 
        <TextInput label="Transaction Id" source="transaction_id" defaultValue="" />  
        <TextInput label="Broker" source="broker" defaultValue="" />       
    </Filter>
);

const newStyle = makeStyles({
    tdcolor: { color: '#4f3cc9' }
});

const WithdrawList = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'user_id', order: 'ASC' }}
            actions={<WithdrawListActions />}
            filters={<WithdrawFilter />}
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<CustomPagination />}            
        >            
            <Datagrid className="lastOrdersColumn">
                <TextField source="user_id" label="User Id" />
                <TextField source="transaction_id" label="TrxID" />
                <DateField source="created" label="Created at" showTime/>
                <TextField source="amount" label="Amount" />
                <TextField source="currency" label="Currency" />
                <TextField source="min_confirm" label="Confirmation" />
                <TextField source="broker" label="Broker"/>
            </Datagrid>
        </List>
    )
};
export default WithdrawList;