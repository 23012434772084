import {
    DateField,    
    TextField,
    TextInput,
    BooleanField,
    SelectInput,
    ImageField,
    required
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import CustomFlagsField from './CustomFlagsField';
import CustomLinkField from './CustomLinkField';
import { makeStyles } from '@material-ui/core/styles';


const IPWhiteListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />}  />
)

const newStyle = makeStyles((theme)=>({
    tdcolor: { color: (theme.palette.type === 'light') ? '#4f3cc9' : '#90caf9' }
}));

const IPWhitelistForm = props => (
    <RowForm {...props}>
        <TextField source="sn" label="SN" />
        <TextInput source="ip" autoFocus label="IP address" validate={required()} />
        <CustomFlagsField source="flags" label="Flags" validate={required()} className='tableimages'/>
        <CustomLinkField source="created_by" label="Whitelisted by" validate={required()} />
        <DateField source="created_at" label="Created at" showTime/>
    </RowForm>
);


const IpWhiteList = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            sort={{ field: 'id', order: 'ASC' }}
            actions={<IPWhiteListActions />}
            title="IP whitelist"
        >
            <EditableDatagrid
                className="lastOrdersColumn"
                rowClick="edit"
                createForm={<IPWhitelistForm />}
                editForm={<IPWhitelistForm />}
            >
                <TextField source="sn" label="SN" />
                <TextField source="ip" label="IP address" />
                <CustomFlagsField source="flags" label="Flags" className='tableimages' />
                <CustomLinkField source="created_by" label="Whitelisted by"/>                
                <DateField source="created_at" label="Created at" showTime />
            </EditableDatagrid>
        </List>
    )
};
export default IpWhiteList;