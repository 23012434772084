import { Fragment } from 'react';
import JWT from "jsonwebtoken";
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const BuyPairBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('markets', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('markets', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const BuyPairActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('markets', 'export') == 'ok') ? true : false} />
)

const BuyPairFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Markets" source="pair" defaultValue="" />       
    </Filter>
);

const newStyle = makeStyles({    
    tdcolor: { color: '#4f3cc9' }
});

const BuyPair = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'pair', order: 'ASC' }}
            actions={<BuyPairActions />}
            filters={<BuyPairFilter />}
            bulkActionButtons={<BuyPairBulkActionButtons />}
            pagination={<CustomPagination />}
            title="Buy pair"
        >
            <Datagrid rowClick="edit">
                <TextField source="pair" label="Markets" />
                <TextField source="course_price" label="Course price" />
                <TextField source="process_fee" label="Process fee" />
                <TextField source="orginal_fee" label="Orginal fees" />
                <TextField source="buy_rate" label="Buy rates" />
                <TextField source="buy_rate_broker" label="Buy broker" />
                <TextField source="grate" label="Expire time" />
                <BooleanField source="status" label="Status" />
            </Datagrid>
        </List>
    )
};
export default BuyPair;