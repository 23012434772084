import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@material-ui/icons/TrendingDownOutlined";
import { Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const BestPriceField = ({ record, size }) => {
    const classes = useStyles();
    console.log(record.best_price);
    return record ? (
        <div className={classes.root}>
            {(record.best_price == 'High price') ?
                <Tooltip title={record.best_price}><TrendingUpOutlinedIcon
                    record={record}
                    size={size}
                    style={{ color: 'rgb(30, 183, 144)' }}
                /></Tooltip> : ((record.best_price == 'Low price') ? <Tooltip title="Low Price"><TrendingDownOutlinedIcon
                    record={record}
                    size={size}
                    style={{ color: 'red' }}
                /></Tooltip> : ((record.best_price == 'Last price') ? <Tooltip title={record.best_price}><UndoOutlinedIcon
                    record={record}
                    size={size}
                    style={{ color: 'rgb(30, 183, 144)' }}
                /></Tooltip> : <Tooltip title="No Price"><CloseIcon
                    record={record}
                    size={size}
                /></Tooltip>))}
        </div>
    ) : null;
};

export default BestPriceField;
