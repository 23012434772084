import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const NewCustomers = ({ customers = [] }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Card className={classes.root}>
            <CardHeader title={translate('New customers')} className="dashboard-title-sizes"/>
            <List dense={true}>
                {customers.map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/users/${record.id}`}
                    >
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>                        
                        <ListItemText
                            primary={new Date(record.created_at).toLocaleString('en-GB')}
                            secondary={translate(record.full_name, {  
                                customer_name: record.full_name,
                            })}
                        />
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default NewCustomers;
