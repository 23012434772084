import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
    SelectInput,
    ImageField,
    required
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const SettingsBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('settings', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('settings', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const FiatCurrencyListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" exporter={(idRoleAllow('settings', 'export') == 'ok') ? true : false} />} />
)

const FiatCurrencyForm = props => (
    <RowForm {...props}>
        <TextInput source="full_name" autoFocus label="Currency" validate={required()}/>
        <TextInput source="short_name" label="Symbol" validate={required()}/>
        <TextInput source="range_from" label="Min amount" validate={required()}/>
        <TextInput source="range_to" label="Max amount" validate={required()}/>
        <TextInput source="reserve_amount" label="Reserved" validate={required()}/>
        <TextInput source="process_fee" label="Process fee" validate={required()}/>
        <TextInput source="deposit_fee" label="Deposit fee" validate={required()}/>
        <TextField source="created" label="Date"/>
        <SelectInput source="status" choices={status_choices} label="Status" validate={required()}/>
    </RowForm>
);

const feeType_choices = [
    { id: 'Percentage', name: 'Percentage' },
    { id: 'Fixed', name: 'Fixed' }];

const status_choices = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' }];

const Fiatcurrencies = (props) => {
    //const classes = newStyle();
    return (
        <List
            {...props}
            hasCreate
            empty={false}
            sort={{ field: 'full_name', order: 'ASC' }}
            actions={<FiatCurrencyListActions />}
            title="Fiat currencies"
            bulkActionButtons={<SettingsBulkActionButtons />}
        >
            <EditableDatagrid
                className="lastOrdersColumn"
                rowClick="edit"
                createForm={<FiatCurrencyForm />}
                editForm={<FiatCurrencyForm />}
            >
                <TextField source="full_name" label="Currencies" />
                <TextField source="short_name" label="Symbol" />
                <TextField source="range_from_alter" label="Min amount" />
                <TextField source="range_to_alter" label="Max amount" />
                <TextField source="reserve_amount_alter" label="Reserved" />
                <TextField source="process_fee_alter" label="Process fee" />
                <TextField source="deposit_fee_alter" label="Deposit fee" />
                <DateField source="created" label="Date" />
                <BooleanField source="status" label="Status" />
            </EditableDatagrid>
        </List>
    )
};
export default Fiatcurrencies;