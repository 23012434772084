import React, { FC } from 'react';

import RefUidField from './RefUidField';

const RefUidLink = props =>
    props.record ? (
            <RefUidField {...props} />
    ) : null;

    RefUidLink.defaultProps = {
    source: 'referee_id',
    addLabel: true,
};

export default RefUidLink;
