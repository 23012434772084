import React from "react";
import { withStyles } from "@material-ui/core/styles";
import EventItem from "./EventItem";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    marginTop: "5px",
  },
  truncate: {
    overflow: "hidden",
  },
  day: {
    marginBottom: "1em",
  },
};

const getDayString = (date) =>
  new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

const EventListView = ({ events = [], classes }) => (
  <div className={classes.root}>
    <div
      title="Timeline"
      className="dashboard-title-sizes"
      style={{ marginBottom: "15px", paddingLeft: "15px" }}
    >
      <strong>Timeline</strong>
    </div>
    <div>
      {events.length > 0
        ? events.map((eventobj, index) => (
          <div key={index} className={classes.day}>
            <Typography
              variant="subheading"
              style={{ paddingLeft: "15px", display: 'flex', marginBottom: '10px' }}
            >
              {getDayString(eventobj.single_date)}
            </Typography>
            {eventobj.event_row.map((erow) => (
              <EventItem event={erow} key={erow.id} />
            ))}
          </div>
        ))
        : ""}
    </div>
  </div>
);

const EventListDashboard = withStyles(styles)(EventListView);

export default EventListDashboard;
