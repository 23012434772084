import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    ImageField,
    required,
    SelectInput
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import CustomOSField from './CustomOSField';
import CustomBrowserField from './CustomBrowserField';
import CustomCountryField from './CustomCountryField';
import CustomDeviceField from './CustomDeviceField';
import CustomStatusField from './CustomStatusField';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            return_value = 'ok';
        }
    });
    return return_value;
}
const PostBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('blocked', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('blocked', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const BlockListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('blocked', 'export') == 'ok') ? true : false} />
)

const BlockFilter = (props) => (
    <Filter {...props}>
        <TextInput label="User IP" source="bip" defaultValue="" />
        <DateInput label="Blocked since" source="created_date" defaultValue="" />
        <DateInput label="Blocked before" source="release_time" defaultValue="" />
    </Filter>
);

const newStyle = makeStyles({
    tdcolor: { color: '#4f3cc9' }
});

const block_status_choices = [
    { id: 'Blocked', name: 'Blocked' },
    { id: 'Unblocked', name: 'Unblocked' }];

const BlockedForm = (props) => (
    <RowForm {...props}>
        <TextField source="sn" label="SN" />
        <TextField source="bip" label="IP address" />
        <CustomCountryField source="country" label="Country" className='tableimages' />
        <CustomDeviceField source="device" label="Device" />
        <CustomOSField source="os" label="OS" className='tableimages' />
        <CustomBrowserField source="browser" height='16' label="Browser" className='tableimages' />
        <TextField source="topics" label="Topics" />
        <DateField source="created_date" label="Blocked at" showTime />
        <SelectInput source="block_status" choices={block_status_choices} label="Status" validate={required()} />
    </RowForm>
);

const BlockLists = (props) => {
    const classes = newStyle();
    return (

        <List
            {...props}
            //hasCreate
            sort={{ field: 'bip', order: 'ASC' }}
            actions={<BlockListActions />}
            filters={<BlockFilter />}
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<CustomPagination />}
            title={'Blocked'}
        >
            <EditableDatagrid
                className="lastOrdersColumn"
                rowClick="edit"
                noDelete="true"
                createForm={<BlockedForm />}
                editForm={<BlockedForm />}
            >
                <TextField source="sn" label="SN" />
                <TextField source="bip" label="IP address" />
                <CustomCountryField source="country" label="Country" className='tableimages' />
                <CustomDeviceField source="device" label="Device" />
                <CustomOSField source="os" label="OS" className='tableimages' />
                <CustomBrowserField source="browser" height='16' label="Browser" className='tableimages' />
                <TextField source="topics" label="Topics" />
                <DateField source="created_date" label="Blocked at" showTime />
                <CustomStatusField source="block_status" label="Status" />
            </EditableDatagrid>
        </List>
    )
};
export default BlockLists;