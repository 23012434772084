import React, { FC } from 'react';

import EarnCommissionLinkField from './EarnCommissionLinkField';

const EarnCommissionLink = props =>
    props.record ? (
            <EarnCommissionLinkField {...props} />
    ) : null;

    EarnCommissionLink.defaultProps = {
    source: 'sourced_orders_id',
    addLabel: true,
};

export default EarnCommissionLink;
