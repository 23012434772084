import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const DaemonField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {(record.auto_payment_active == true) ? <img src="../images/deamons/ON.svg" style={{height:'18px'}}/> : <img src="../images/deamons/OFF.svg" style={{height:'18px'}}/>}
        </div>
    ) : null;
};

export default DaemonField;
