import React, { FC } from 'react';
import { Link } from 'react-admin';

import FlagsField from './FlagsField';

const CustomFlagsField = props =>
    props.record ? (
            <FlagsField {...props} />
    ) : null;

    CustomFlagsField.defaultProps = {
    source: 'flags',
    addLabel: true,
};

export default CustomFlagsField;
