import * as React from "react";
import api_url from "./ApiUrl";
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { Create } from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import JWT from "jsonwebtoken";

import {
  useState,
  useEffect,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox
} from "@material-ui/core";

const roleData = [];
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const RoleBaseSettingAccountant = (props) => {
  const [roleData, setRoleData] = useState({});
  useEffect(() => {
    getRoleLogWithFetch();
  }, []);
  const getRoleLogWithFetch = async () => {
    let login_token = localStorage.getItem("auth");
    let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
      algorithm: "HS512",
    });
    const request = new Request(api_url() + "/get_roledata", {
      method: "POST",
      body: JSON.stringify({ role_type: "accountant" }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${login_token}`,
        'xelxtoken': apitoken
      }),
    });
    const response = await fetch(request);
    const jsonData = await response.json();
    setRoleData(jsonData);
  };
  //console.log(roleData);

  const [checked, setChecked] = useState();
  const handleChange = async (event) => {
    console.log(event.target.checked);
    let click_id = event.target.id;
    let click_status = event.target.checked;
    let login_token = localStorage.getItem("auth");
    const request = new Request(api_url() + "/update_role", {
      method: "POST",
      body: JSON.stringify({ getroledata: click_id, status: click_status }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${login_token}`,
      }),
    });
    const response = await fetch(request);
    const jsonData = await response.json();
    getRoleLogWithFetch();
  };
  //const classes = useStyles();
  const AccountantRoleActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={false} />
  )
  return (
    <Create
      {...props}
      actions={<AccountantRoleActions />} title="Create role accountant">
      <TableContainer component={Paper} className="custom_role_table" style={{ marginTop: '0px', border: '0px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Resource</TableCell>
              <TableCell align="center">List</TableCell>
              <TableCell align="center">Show</TableCell>
              <TableCell align="center">Create</TableCell>
              <TableCell align="center">View</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
              <TableCell align="center">Read</TableCell>
              <TableCell align="right">Export</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(roleData && roleData.length > 0) && roleData.map((records, index) => {
              if (records.resource_name != 'rolesetting') {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" style={{ textTransform: 'capitalize' }}>
                      {records.resource_name_alter}
                    </TableCell>
                    <TableCell align="center"><Checkbox {...label} id={records.id + "-list-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.list == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                    <TableCell align="center"><Checkbox {...label} id={records.id + "-show-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.show == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                    <TableCell align="center"><Checkbox {...label} id={records.id + "-create-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.create == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                    <TableCell align="center"><Checkbox {...label} id={records.id + "-view-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.view == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                    <TableCell align="center"><Checkbox {...label} id={records.id + "-edit-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.edit == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                    <TableCell align="center"><Checkbox {...label} id={records.id + "-delete-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.delete == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                    <TableCell align="center"><Checkbox {...label} id={records.id + "-read-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.read == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                    <TableCell align="right"><Checkbox {...label} id={records.id + "-export-" + records.resource_name_alter + "-role_name-" + records.role_name} checked={(records.export == 1) ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Create>
  );
};

export default RoleBaseSettingAccountant;
