import React, { FC } from 'react';
import ActivityStatusField from './ActivityStatusField';

const UserEmailStatus = props =>
    props.record ? (
        <ActivityStatusField {...props} />
    ) : null;

UserEmailStatus.defaultProps = {
    source: 'is_loggedin',
    addLabel: true,
};

export default UserEmailStatus;
