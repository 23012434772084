//import { Breadcrumb, BreadcrumbItem } from '@react-admin/ra-navigation';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbProps,
    ResourceBreadcrumbItems,
} from '@react-admin/ra-navigation';
import { makeStyles } from '@material-ui/core/styles';
import { linkToRecord } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        fontSize: 'small',
        maxWidth: '700px',
        zIndex: 1,
        '& a': {
            pointerEvents: 'visible',
        },
    },
}));

export const CustomBreadcrumb = (props) => {
    const classes = useStyles();
    //const translate = useTranslate();
    const editLabel = "Edit";
    const createLabel = "Create";
    const showLabel = "Show";

    return (
        <Breadcrumb className={classes.root} {...props}>

            <BreadcrumbItem
                name="dashboard"
                label="Dashboard"
                to="/dashboard"
            >
                <BreadcrumbItem
                    name="users"
                    label="Users"
                    to={({ record }) => record ? `${'/users?filter={"type":"' + record.vip_status + '"}'}` : ''}
                >
                    <BreadcrumbItem
                        name="edit"
                        label={({ record }) => `${editLabel} ${record ? `"${record.full_name}"` : '...'}`}
                        to={({ record }) => record ? `${linkToRecord('/users', record.id)}/edit` : ''}
                    />
                    <BreadcrumbItem
                        name="create"
                        label={createLabel}
                        to="/users/create"
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="locked"
                    label="Locked"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="unlocked"
                    label="Unlocked"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="countrysetting"
                    label="Country list"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="blocked"
                    label="Blocked"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="orders"
                    label="Orders"
                    to={({ record }) => record ? `${'/orders?filter={"type":"' + record.lock_type + '"}'}` : ''}
                >
                    <BreadcrumbItem
                        name="show"
                        label={({ record }) => `${showLabel} ${record ? `"${record.transaction_id}"` : '...'}`}
                        to={({ record }) => record ? `${linkToRecord('/orders', record.id)}/show` : ''}
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="deposits"
                    label="deposit"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="withdraws"
                    label="Withdraw"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="basecurrency"
                    label="Base currency"
                    to={'/basecurrency?filter={"type":"basecurrency"}'}
                >
                    <BreadcrumbItem
                        name="edit"
                        label="Edit-BTC"
                        label={({ record }) => `${editLabel} ${record ? `"${record.full_name}"` : '...'}`}
                        to={''}
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="cryptocurrency"
                    label="Cryptocurrency"
                    to={'/cryptocurrency?filter={"type":"cryptocurrency"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="fiatcurrencies"
                    label="Fiat currencies"
                    to={'/fiatcurrencies?filter={"type":"fiatcurrencies"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="ecurrencies"
                    label="E-currencies"
                    to={'/ecurrencies?filter={"type":"ecurrencies"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="ipwhitelist"
                    label="IP whitelist"
                    to={'/ipwhitelist?filter={"type":"all"}'}
                >
                </BreadcrumbItem>


                {/* =========================================================== */}
                <BreadcrumbItem
                    name="depositusdctrc20"
                    label="Deposit usdc-trc20"
                    to={'/depositusdctrc20?filter={"type":"USDC-TRC20"}'}
                >
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="depositbitcoin"
                    label="Deposit bitcoin"
                    to={'/depositbitcoin?filter={"type":"bitcoin"}'}
                >
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="depositethereum"
                    label="Deposit ethereum"
                    to={'/depositethereum?filter={"type":"ethereum"}'}
                >
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="depositusdterc20"
                    label="Deposit usdt-erc20"
                    to={'/depositusdterc20?filter={"type":"USDT-ERC20"}'}
                >
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="depositusdttrc20"
                    label="Deposit usdt-trc20"
                    to={'/depositusdttrc20?filter={"type":"USDT-TRC20"}'}
                >
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="depositusdcerc20"
                    label="Deposit usdc-erc20"
                    to={'/depositusdcerc20?filter={"type":"USDC-ERC20"}'}
                >
                </BreadcrumbItem>
                {/* =========================== */}

                <BreadcrumbItem
                    name="depositpmusd"
                    label="Deposit Perfect Money"
                    to={'/depositpmusd?filter={"type":"PMUSD"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="depositadvcusd"
                    label="Deposit Advcash"
                    to={'/depositadvcusd?filter={"type":"ADVCUSD"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="depositpayeer"
                    label="Deposit Payeer"
                    to={'/depositpayeer?filter={"type":"PAYEER"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="depositfpusd"
                    label="Deposit Fasapay"
                    to={'/depositfpusd?filter={"type":"FPUSD"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="pricestore"
                    label="Exchange rate"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="sellrate"
                    label="Sell rate"
                    to=""
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="brokerage"
                    label="Brokerage"
                    to={'/brokerage?filter={"type":"brokerage"}'}
                >
                    <BreadcrumbItem
                        name="edit"
                        label="Edit-Brokerage"
                        label={({ record }) => `${editLabel} ${record ? `"${record.broker}"` : '...'}`}
                        to={''}
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="exchangepair"
                    label="Exchange pair"
                    to={'/exchangepair?filter={"type":"exchangepair"}'}
                >
                    <BreadcrumbItem
                        name="edit"
                        label="Edit-exchangepair"
                        label={({ record }) => `${editLabel} ${record ? `"${record.pair}"` : '...'}`}
                        to={''}
                    />
                    <BreadcrumbItem
                        name="create"
                        label={createLabel}
                        to="/exchangepair/create"
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="buypair"
                    label="Buy pair"
                    to={'/buypair?filter={"type":"buypair"}'}
                >
                    <BreadcrumbItem
                        name="edit"
                        label="Edit-buypair"
                        label={({ record }) => `${editLabel} ${record ? `"${record.pair}"` : '...'}`}
                        to={''}
                    />
                    <BreadcrumbItem
                        name="create"
                        label={createLabel}
                        to="/buypair/create"
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="sellpair"
                    label="Sell pair"
                    to={'/sellpair?filter={"type":"sellpair"}'}
                >
                    <BreadcrumbItem
                        name="edit"
                        label="Edit-sellpair"
                        label={({ record }) => `${editLabel} ${record ? `"${record.pair}"` : '...'}`}
                        to={''}
                    />
                    <BreadcrumbItem
                        name="create"
                        label={createLabel}
                        to="/sellpair/create"
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="earncommission"
                    label="Earn commission"
                    to={'/earncommission?filter={"type":"earncommission"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="referralcommission"
                    label="Referral commission"
                    to={''}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="referralinfos"
                    label="Referee info"
                    to={''}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="withdrawfees"
                    label="Withdraw fee"
                    to={''}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="pendingcommission"
                    label="Pending withdraw"
                    to={'/pendingcommission?filter={"type":"pending"}'}
                >
                    <BreadcrumbItem
                        name="show"
                        label="Show-pending"
                        label={({ record }) => `${showLabel} ${record ? `"${record.withdraw_id}"` : '...'}`}
                        to={''}
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="canceledcommission"
                    label="Canceled withdraw"
                    to={'/canceledcommission?filter={"type":"canceled"}'}
                >
                    <BreadcrumbItem
                        name="show"
                        label="Show-canceled"
                        label={({ record }) => `${showLabel} ${record ? `"${record.withdraw_id}"` : '...'}`}
                        to={''}
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="completedcommission"
                    label="Completed withdraw"
                    to={'/completedcommission?filter={"type":"completed"}'}
                >
                    <BreadcrumbItem
                        name="show"
                        label="Show-completed"
                        label={({ record }) => `${showLabel} ${record ? `"${record.withdraw_id}"` : '...'}`}
                        to={''}
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="termsofuse"
                    label="Terms of use"
                    to={'/termsofuse?filter={"type":"termsofuse"}'}
                ></BreadcrumbItem>
                <BreadcrumbItem
                    name="privacypolicy"
                    label="Privacy policy"
                    to={'/privacypolicy?filter={"type":"privacypolicy"}'}
                ></BreadcrumbItem>
                <BreadcrumbItem
                    name="amlpolicy"
                    label="AML policy"
                    to={'/amlpolicy?filter={"type":"amlpolicy"}'}
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="media"
                    label="Media"
                    to={'/media?filter={"type":"media"}'}
                >
                    <BreadcrumbItem
                        name="edit"
                        label="Edit-media"
                        to={''}
                    />
                    <BreadcrumbItem
                        name="create"
                        label="Create-media"
                        //label={createLabel}
                        to={''}
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="news"
                    label="News"
                    to={'/news?filter={"type":"news"}'}
                >
                    <BreadcrumbItem
                        name="edit"
                        label="Edit-news"
                        label={({ record }) => `${editLabel} ${record ? `"${record.id}"` : '...'}`}
                        to={''}
                    />
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="campaign"
                    label="Campaign"
                    to={'/campaign?filter={"type":"campaign"}'}
                >
                </BreadcrumbItem>

                <BreadcrumbItem
                    name="events"
                    label="Events"
                    to={'/events?filter={"type":"events"}'}
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="rolesetting"
                    label="Role-admin"
                    to={'/rolesetting?filter={"type":"admin"}'}
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="rolesettingaccountant"
                    label="Role-accountant"
                    to={'/rolesettingaccountant?filter={"type":"accountant"}'}
                ></BreadcrumbItem>

                <BreadcrumbItem
                    name="rolesettingeditor"
                    label="Role-editor"
                    to={'/rolesettingeditor?filter={"type":"editor"}'}
                ></BreadcrumbItem>
                <BreadcrumbItem
                    name="rolesettingauditor"
                    label="Role-auditor"
                    to={'/rolesettingauditor?filter={"type":"auditor"}'}
                ></BreadcrumbItem>


            </BreadcrumbItem>
        </Breadcrumb>
    )
};