import React, { FC } from 'react';

import TIdLinkField from './TIdLinkField';

const TransactionIdLink = props =>
    props.record ? (
            <TIdLinkField {...props} />
    ) : null;

    TransactionIdLink.defaultProps = {
    source: 'withdraw_id',
    addLabel: true,
};

export default TransactionIdLink;
