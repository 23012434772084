import React, { FC } from 'react';
import { Fragment } from 'react';
import {
    DateField,
    DateInput,
    TextField,
    BulkDeleteButton,
    BulkExportButton,
    TextInput,
} from 'react-admin';

import { List, ListActions } from '@react-admin/ra-enterprise';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import { CustomBreadcrumb } from './CustomBreadcrumb';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const ReferralCommissionBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('referrals', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('referrals', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const ReferralForm = props => (
    <RowForm {...props}>
        <TextField source="commission_type" />
        <TextInput source="range_from_alter" autoFocus label="Min amount"/>
        <TextInput source="range_to_alter" label="Max amount"/>
        <TextInput source="percent_amount_alter" label="Commission"/>
        <DateInput source="created_at" />
    </RowForm>
);

const ReferralListActions = props => (
    <ListActions
        {...props}
        breadcrumb={<CustomBreadcrumb variant="actions" />}
        exporter={(idRoleAllow('referrals', 'export') == 'ok') ? true : false}
    />
);

const ReferralCommissionList = (props) => (
    <List
        {...props}
        sort={{field: 'commission_type', order: 'ASC'}}
        actions={<ReferralListActions />}
        empty={false}
        perPage={25}
        title="Referral commission"
        bulkActionButtons={<ReferralCommissionBulkActionButtons />}
    >
        <EditableDatagrid
            data-testid="store-datagrid"
            rowClick="edit"
            createForm={<ReferralForm />}
            editForm={<ReferralForm />}
        >
            <TextField source="commission_type" label="Referral type"/>
            <TextField source="range_from" label="Min amount"/>
            <TextField source="range_to" label="Max amount"/>
            <TextField source="percent_amount" label="Commission"/>
            <DateField source="created_at" label="Created at" showTime/>
        </EditableDatagrid>
    </List>
);
export default ReferralCommissionList;
