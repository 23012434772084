import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const ExchangePairField = ({ record }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            Edit {record.from_pair + '/' + record.to_pair}
        </div>
    ) : null;
};

export default ExchangePairField;
