import React, { FC } from 'react';
import { Link } from 'react-admin';

import PaymentModeField from './PaymentModeField';

const PaymentMode = props =>
    props.record ? (
            <PaymentModeField {...props} />
    ) : null;

    PaymentMode.defaultProps = {
    source: 'system_process_by',
    addLabel: true,
};

export default PaymentMode;
