import React, { FC } from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import {
  ToggleThemeButton,
  LanguageSwitcher,
} from "@react-admin/ra-preferences";
import Search from "./Search";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingLeft: theme.spacing(1),
  },
  logo: {
    padding: theme.spacing(0, 2),
  },
}));

const CustomAppBar = (props) => {
  const classes = useStyles();  
  return (
    <AppBar {...props} elevation={1}>
      <>
        <Logo className={classes.logo} />
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <Search />        
        <ToggleThemeButton />
        <LanguageSwitcher
          languages={[
            { locale: "en", name: "English" },
            { locale: "fr", name: "Français" },
          ]}
          defaultLanguage="English"
        />
      </>
    </AppBar>
  );
};

export default CustomAppBar;
