import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
//import { makeStyles } from '@material-ui/core/styles';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}
const NewsBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('news', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('news', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const NewsListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('news', 'export') == 'ok') ? true : false} />
)

// const newStyle = makeStyles({    
//     tdcolor: { color: '#4f3cc9' }
// });

const NewsList = (props) => {
    //const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'created', order: 'DESC' }}
            actions={<NewsListActions />}           
            bulkActionButtons={<NewsBulkActionButtons />}
            pagination={<CustomPagination />}
            title="News"
        >
            <Datagrid rowClick="edit" className="lastOrdersColumn">
                <TextField source="titles" label="Title" />
                <TextField source="details" label="Details"/>                                
                <DateField source="created" showTime label="Create at"/> 
                <BooleanField source="status" label="Status"/>               
            </Datagrid>
        </List>
    )
};
export default NewsList;