import React, { FC, Fragment, useRef } from "react";
import { useTranslate } from "react-admin";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Search,
  SearchProps,
  SearchPanelProps,
  useSearchResults,
  groupSearchResultsByResource,
  useArrowKeysToNavigate,
} from "@react-admin/ra-search";

import {
  OrderListItem,
  CustomerListItem,
  ProductListItem,
  ReviewListItem,
} from "./SearchItems";

const CustomSearch = (props) => {
  //console.log("Called by typing");
  //console.log(props);
  return (
    <div data-testid="search">
      <Search {...props}>
        <CustomSearchPanel></CustomSearchPanel>
      </Search>
    </div>
  );
};

export default CustomSearch;

const CustomSearchPanel = (props) => {
  //console.log(props);
  const listRef = useRef < HTMLUListElement > null;
  const translate = useTranslate();
  const classes = useCustomSearchPanelStyles(props);
  useArrowKeysToNavigate(listRef);
  const { data, onClose } = useSearchResults();
  if (!data || data.length === 0) {
    return (
      <List data-testid="search-panel" dense {...props}>
        <ListItem>
          <ListItemText primary={translate("ra.navigation.no_results")} />
        </ListItem>
      </List>
    );
  }
  //console.log(data.searchArray);
  //const groupedData = groupSearchResultsByResource(data, translate);

  return (
    <List
      className={classes.root}
      data-testid="search-panel"
      dense
      innerRef={listRef}
      {...props}
    >
      {data.searchArray.map((group) => (
        <Fragment key={group.content.label}>
          <ListSubheader
            role="presentation"
            className={classes.header}
            disableSticky
          >
            <>
              <Typography className={classes.headerGroup} variant="subtitle1">
                {translate(group.content.label.toString(), {
                  _: group.content.label,
                })}
              </Typography>
              <Typography className={classes.headerCount} variant="subtitle1">
                {translate(group.content.total_records+' results', {
                  smart_count: group.content.total_records,
                })}
              </Typography>
            </>
          </ListSubheader>

          {group.content.datalist.map((searchResultItem) => (
            <CustomSearchResultItem
              key={searchResultItem.id}
              data={searchResultItem}
              onClose={onClose}
            />
          ))}
        </Fragment>
      ))}
    </List>
  );
};

const useCustomSearchPanelStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "calc(100vh - 100px)",
    maxWidth: 512,
    overflowX: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
  },
  headerGroup: {
    textTransform: "capitalize",
  },
  headerCount: {
    textTransform: "lowercase",
  },
}));

const CustomSearchResultItem = (props) => {
  const { data, onClose, ...rest } = props;
  //console.log(data);
  if (!data) {
    return null;
  }

  switch (data.type) {
    case "customers":
      return <CustomerListItem {...rest} data={data} onClick={onClose} />;
    case "customer_orders":
      return <OrderListItem {...rest} data={data} onClick={onClose} />;
    default:
      return null;
  }
};
