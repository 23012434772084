import React, { FC } from 'react';

import UserLinkField from './UserLinkField';

const UserLink = props =>
    props.record ? (
            <UserLinkField {...props} />
    ) : null;

    UserLink.defaultProps = {
    source: 'referrer_id',
    addLabel: true,
};

export default UserLink;
