import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    SelectInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
    required
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            return_value = 'ok';
        }
    });
    return return_value;
}

const DepositBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('deposits', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('deposits', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const DepositCryptoListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('deposits', 'export') == 'ok') ? true : false} />
)

const status_choices = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' }];

const coin_choices = [{ id: 'USDT-TRC20', name: 'USDT TRC20' }];

const DepositCryptoCurrencyForm = props => (
    <RowForm {...props}>
        <SelectInput source="coin_name" choices={coin_choices} label="Coin name" />
        <TextInput source="address" label="Crypto address" validate={required()} />
        <DateField source="created_at" label="Date" />
        <SelectInput source="status" choices={status_choices} label="Status" validate={required()} />
    </RowForm>
);

const DepositList = (props) => {
    // const classes = newStyle();
    return (
        <List
            {...props}
            hasCreate
            sort={{ field: 'id', order: 'ASC' }}
            actions={<DepositCryptoListActions />}
            bulkActionButtons={<DepositBulkActionButtons />}
            pagination={<CustomPagination />}
            title={'Deposit usdt-trc20'}
        >
            <EditableDatagrid
                className="lastOrdersColumn"
                rowClick="edit"
                noDelete="true"
                createForm={<DepositCryptoCurrencyForm />}
                editForm={<DepositCryptoCurrencyForm />}
            >
                <TextField style={{ textTransform: 'capitalize' }} source="coin_name" label="Coin name" />
                <TextField source="address" label="Crypto address" />
                <DateField source="created_at" label="Created at" showTime />
                <BooleanField source="status" label="Status" />
            </EditableDatagrid>
        </List>
    )
};
export default DepositList;