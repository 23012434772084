import React, { FC } from 'react';
import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CommentIcon from '@material-ui/icons/Comment';
import DollarIcon from '@material-ui/icons/AttachMoney';

import { SearchListItemLink } from './SearchListItemLink';
import { useTranslate } from 'react-admin';
import { LinkedData } from './LinkedData';
import { Link } from "react-router-dom";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined';

const secondaryTypographyProps = {
    component: 'div',
};

export const CustomerListItem = (props) => {
    const { data, onClick } = props;
    const { content } = data;  
    const classes = useStyles();
    const translate = useTranslate();

    if (!data) {
        return null;
    }

    const fullname = `${data.full_name}`;

    return (
        <ListItem
            button
            component={SearchListItemLink}
            data={data}
            onClick={onClick}
            alignItems="flex-start"
            className={classes.root}
            data-testid="customer"
        >
            <ListItemAvatar className={classes.avatar}>
                <Avatar />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography color="textPrimary">{fullname}</Typography>
                }
                secondary={
                    <Box
                        component="ul"
                        display="flex"
                        justifyContent="flex-start"
                        padding={0}
                        marginTop={1}
                        marginBottom={1}
                    >

                        {(data.completed_orders > 0) ? (
                            <LinkedData
                                icon={<ShoppingCartIcon style={{ height: '16px',paddingTop:'4px' }} />}
                                label={translate('Orders', {
                                    smart_count: 2,
                                })}
                                to={`orders?filter={"type":"completed"}`}
                            //className={classes.customWitdth2}
                            >
                                {data.completed_orders}
                            </LinkedData>
                        ) : null}

                        {data.total_spent > 0 ? (
                            <LinkedData
                                icon={<DollarIcon style={{ height: '16px',width:'20px',paddingTop:'4px' }} />}
                                label={translate(
                                    'Total spent'
                                )}
                                to={`orders?filter={"type":"completed"}`}
                            //className={classes.customWitdth}
                            >
                                {data.total_spent.toLocaleString()}
                            </LinkedData>
                        ) : null}

                        {data.total_referrer > 0 ? (
                            <LinkedData
                                icon={<DeviceHubOutlinedIcon style={{ height: '16px',paddingTop:'4px' }} />}
                                label={translate(
                                    'Referrals'
                                )}
                                to="#"
                            //className={classes.customWitdth2}
                            >
                                {data.total_referrer.toLocaleString()}
                            </LinkedData>
                        ) : null}

                        {data.total_affiliate_income > 0 ? (
                            <LinkedData
                                icon={<DollarIcon style={{ height: '16px',paddingTop:'4px' }} />}
                                label={translate(
                                    'Total earning'
                                )}
                                to="#"
                            //className={classes.customWitdth}
                            >
                                {data.total_affiliate_income.toLocaleString()}
                            </LinkedData>
                        ) : null}

                    </Box>
                }
                // @ts-ignore Could not make TS happy
                secondaryTypographyProps={secondaryTypographyProps}
            />
        </ListItem>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        '& a': {
            textDecoration: 'none',
        },
        '& a:hover': {
            textDecoration: 'none',
        },
    },
    customWitdth: {
        width: '125px !important'
    },
    customWitdth2: {
        width: '55px !important'
    },
    avatar: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        '& img': {
            width: 50,
            height: 50,
        },
    },
}));
