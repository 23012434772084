import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link,ReferenceOneField, TextField } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const UserLinkField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            <Link to={`/users/${record.user_id}`} >{record.referrer_id}</Link>
        </div>
    ) : null;
};

export default UserLinkField;
