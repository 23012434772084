import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const ActivityStatusField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {(record.is_loggedin == 'Yes') ? <img src="../images/deamons/online.svg" style={{ width: '55px' }} /> : <img src="../images/deamons/offline.svg" style={{ width: '55px'}} />}
        </div>
    ) : null;
};

export default ActivityStatusField;
