//import * as React from "react";
import React, { useState, useEffect } from "react";
import JWT from "jsonwebtoken";
import Switch from '@material-ui/core/Switch';
import { SelectArrayInput } from 'react-admin';
import {
    Edit,
    SelectInput,
    FormWithRedirect,
    TextInput,
    SaveButton,
    DeleteButton,
    Link,
    Toolbar,
    useNotify,
    useRedirect,
    useWarnWhenUnsavedChanges,
    NumberInput,
    Create, Show, SimpleForm, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton, required
} from 'react-admin';
import { Box, Card, CardContent, Typography, Grid, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api_url from './ApiUrl';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { MarkdownInput } from '@react-admin/ra-markdown';

const options = {
    previewStyle: "tab",
    height: "300px",
    initialEditType: "markdown",
    useCommandShortcut: false,
};

const useStyles = makeStyles({
    inputHeight: { height: '25px !important' }
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton
            label="Save"
            redirect="show"
            submitOnEnter={true}
            disabled={props.pristine}
        />
        <Link to={'/dashboard?filter={"type":"dashboard"}'}><Button variant="outlined" color="default" style={{ marginLeft: '15px' }}>Cancel</Button></Link>
    </Toolbar>
);
const validateCampaignData = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'The email is required';
    }
    if (!values.subject) {
        errors.subject = 'The subject is required';
    }
    if (!values.message) {
        errors.message = 'Message is required';
    }
    return errors
};

const CampaignForm = (props) => {
    const classes = useStyles();
    const [emailRow, setEmailData] = useState({});
    useEffect(() => {
        getUserEmailWithFetch();
    }, []);
    const getUserEmailWithFetch = async () => {
        let login_token = localStorage.getItem('auth');
        let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
            algorithm: "HS512",
          });
        const request = new Request(api_url() + '/getCampaignEmail', {
            method: 'POST',
            body: JSON.stringify({ 'getemail': 'OK' }),
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${login_token}`,'xelxtoken': apitoken }),
        });
        const response = await fetch(request);
        const jsonData = await response.json();
        setEmailData(jsonData.email_row);
    };
    useWarnWhenUnsavedChanges(true);
    return (
        <div>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Box flex={1} mr="1em">
                            <Grid container>

                                {(emailRow && emailRow.length > 0) ? <Grid item xs={8}>
                                    <Box>
                                        <TextInput
                                            label="Email lists"
                                            variant="outlined"
                                            fullWidth
                                            source="email"
                                            defaultValue={emailRow}
                                        />
                                    </Box>
                                </Grid> : ''}

                                <Grid item xs={8}>
                                    <Box>
                                        <TextInput variant="outlined" source="subject" label="Subject" fullWidth />
                                    </Box>
                                </Grid>

                                <Grid item xs={8}>
                                    <Box>
                                        <MarkdownInput variant="outlined" source="message" options={options} label="Message" fullWidth />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </div>
    )
};
const CampaignAddActions = (props) => (
    <ListActions  {...props} exporter={false} breadcrumb={<CustomBreadcrumb variant="actions" />} />
)
const Campaign = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('Mass mail sent successfully');
        redirect('/campaign?filter={"type":"campaign"}');
    }
    const onFailure = (error) => {
        if (error.code == 429) {
            notify('Could not save: Pair already exist', 'warning');
        } else {
            notify('ra.notification.http_error', 'warning')
        }
        redirect('/campaign?filter={"type":"campaign"}');
    }
    return (
        <Create {...props}
            actions={<CampaignAddActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}>
            <SimpleForm toolbar={<CustomToolbar />} validate={validateCampaignData}>
                <CampaignForm />
            </SimpleForm>
        </Create>
    )
};
export default Campaign;