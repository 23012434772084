import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@material-ui/icons/TrendingDownOutlined";
import { Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    iconColorSemiauto: { color: (theme.palette.type === 'light') ? 'rgb(79, 60, 201)' : 'rgb(144,202,249)' },
    iconColorManual: { color: (theme.palette.type === 'light') ? 'rgb(128,128,128)' : 'rgb(255,255,255)' },
}));


const PaymentModeField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {(record.system_process_by == 'Automatic') ?
                <Tooltip title="Automatic"><LoopOutlinedIcon
                    record={record}
                    size={size}
                    style={{ color: 'rgb(30, 183, 144)' }}
                /></Tooltip> : ((record.system_process_by == 'Semi-automatic') ? <Tooltip title="Semi-automatic"><SettingsOutlinedIcon
                    record={record}
                    size={size}
                    className={classes.iconColorSemiauto}
                    //style={{ color: 'rgb(79, 60, 201)' }}
                /></Tooltip> : <Tooltip title="Manual"><UpdateOutlinedIcon
                    record={record}
                    size={size}
                    className={classes.iconColorManual}
                    //style={{ color: 'rgb(128,128,128)' }}
                /></Tooltip>)}
        </div>
    ) : null;
};

export default PaymentModeField;
