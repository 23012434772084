import React, { FC } from 'react';
import FeeTypeField from './FeeTypeField';

const FeesField = props =>
    props.record ? (
        <FeeTypeField {...props} />
    ) : null;

FeesField.defaultProps = {
    addLabel: true,
};

export default FeesField;
