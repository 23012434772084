import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
//import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  Avatar,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  cost: {
    marginRight: "1em",
    color: theme.palette.text.primary,
    fontSize:'0.875rem',
  },
  ordericonbg: {
    backgroundColor: "#bdbdbd",
    borderRadius: "50%",
    padding: "8px",
    color: "#fcfcfe",
  },
}));

const CompletedOrders = ({ orders = [] }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card className={classes.root}>
      <CardHeader
        title={translate("Completed orders")}
        className="dashboard-title-sizes"
      />
      <List dense={true}>
        {orders.map((record) => (
          <ListItem
            key={record.id}
            button
            component={Link}
            to={`/orders/${record.id}/show`}
          >
            <Avatar style={{ marginRight: '15px' }}>
              <CheckCircleOutlineIcon fontSize="large" style={{fontSize:'1.8rem'}}/>
            </Avatar>
            <ListItemText
              primary={new Date(record.created_at).toLocaleString("en-GB")}
              secondary={translate(record.from_coin + "/" + record.to_coin, {
                nb_items: record.from_coin + "/" + record.to_coin,
                customer_name: record.user_email,
              })}
            />
            <ListItemSecondaryAction style={{ marginTop: "10px" }}>
              <span className={classes.cost}>{record.send_amount}</span>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default CompletedOrders;
