import * as React from 'react';
import { Grid, Typography, Container, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


function CircularProgressWithLabel({ value }) {
    return (
        <Container sx={{ minWidth: '100%', padding: '1px 0px' }}>
            <Grid item xs={12} md={12}>
                <Box sx={{ position: 'relative', width: '150px', top: '220px', margin: '0 auto', verticalAlign: 'middle' }}>
                    <CircularProgress thickness={2} />
                </Box>
            </Grid>
        </Container>
    );
}

export default function ProgressBar() {
    return <CircularProgressWithLabel />;

}