import { ThemeOptions } from '@react-admin/ra-enterprise';
import { Theme } from '@material-ui/core';
import { makeStyles, darken, fade, lighten } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';

const darkPalette = createPalette({
    type: 'light',
    primary: {
        main: '#4f3cc9',
    },
    secondary: {
        light: '#5f5fc4',
        main: '#283593',
        dark: '#001064',
        contrastText: '#fff',
    },
    background: {
        default: '#fcfcfe',
    },
});

const DarkTheme = ({
    palette: darkPalette,
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaSearchInput: {
            root: {
                color: darkPalette.text.primary,
                backgroundColor: fade(darkPalette.common.black, 0.04),
                '&:hover': {
                    backgroundColor: fade(darkPalette.common.black, 0.13),
                },
                '&:focus': {
                    backgroundColor: fade(darkPalette.common.black, 0.13),
                },
                '&:focus-within': {
                    backgroundColor: fade(darkPalette.common.black, 0.13),
                },
            },
            inputBase: {
                background: fade(darkPalette.common.black, 0.04),
                borderRadius: 10,
                '&:hover': {
                    background: fade(darkPalette.common.black, 0.1),
                },
            },
            inputAdornmentStart: {
                color: darkPalette.text.hint,
            },
        },
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #808080',
            },
        },
        RaMenuItemCategory: {
            root: {
                color: '#808080',
                '&:hover': {
                    color: 'black',
                    backgroundColor: '#000',
                },
            },
        },
        RaMenuItem: {
            root: {
                color: '#808080',
                '&:hover': {
                    color: '#fff',
                    backgroundColor: '#000',
                },
            },
        },
        RaMultiLevelMenu: {
            navWithCategories: {
                backgroundColor: '#fff',
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#4f3cc9',
                boxShadow: 'none',
            },
        },
        MuiAppBar: {
            // Hide MenuItemCategory shadow behind the appbar
            root: { zIndex: 9999 },
            colorSecondary: {
                color: '#808080',
                backgroundColor: '#fff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        RaSidebar: {
            drawerPaper: {
                paddingRight: 16,
                width: 'auto',
            },
        },
        RaFilterFormInput: {
            body: {
                // Fixes search filter breadcrumb overlap
                '& > div': {
                    marginTop: 8,
                },
            },
        },
        RaLinkedData: {
            root: {
                '&:hover': {
                    backgroundColor: '#ddd',
                },
            },
        },
        RaTopToolbar: {
            root: {
                alignItems: 'center',
                paddingTop: 0,
                minHeight: 'auto',
            },
        },
        RaListToolbar: {
            root: {
                alignItems: 'center',
                paddingTop: 0,
            },
            actions: {
                alignItems: 'center',
                minHeight: 'auto',
                paddingTop: 0,
            },
            toolbar: {
                minHeight: 'auto',
            },
        },
    },
});

export default DarkTheme;
