import React, { FC } from 'react';
import { Link } from 'react-admin';

import BestPriceField from './BestPriceField';

const BestPriceIcon = props =>
    props.record ? (
            <BestPriceField {...props} />
    ) : null;

    BestPriceIcon.defaultProps = {
    source: 'best_price',
    addLabel: true,
};

export default BestPriceIcon;
