import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@material-ui/icons/TrendingDownOutlined";
import { Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    ImageField,
} from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const BrowserField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            <Tooltip title={record.browser_name}>
                <img src={record.browser} height='16'/>
            </Tooltip>
        </div>
    ) : null;
};

export default BrowserField;
