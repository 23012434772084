import * as React from "react";
import Switch from '@material-ui/core/Switch';
import {
    Edit,
    SelectInput,
    FormWithRedirect,
    TextInput,
    SaveButton,
    DeleteButton,
    Link
} from 'react-admin';
import { Box, Card, CardContent, Typography, Toolbar, Grid, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api_url from './ApiUrl';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { List, ListActions } from '@react-admin/ra-enterprise';


const useStyles = makeStyles({
    ownfont14: { fontSize: '14px', paddingBottom: '7px' },
    ownfont12: { fontSize: '12px' },
    reactSwitch: { pointerEvents: 'none' },
    paddingTop: { paddingTop: '0px' },
    fweight500: { fontWeight: 500 },
    googleFa: { fontSize: '1rem', fontWeight: 500 }
});


const EditMediaForm = (props) => {
    const classes = useStyles();
    return (
        <FormWithRedirect {...props} render={formProps => (
            <div>
                <CardContent>
                    <Grid container>

                        <Grid item xs={12}>
                            <Box flex={1} mr="1em">
                                <Grid container style={{ marginBottom: '10px' }}>
                                    <Grid item xs={12} p="0">
                                        <Box flex={1} mr="1em">
                                            Media edit
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Box mr="1em">
                                            <TextInput source="media_logo" resource="media" label="Logo" fullWidth />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Box mr="1em">
                                            <TextInput source="media_link" resource="media" label="Link" fullWidth />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Box mr="1em">
                                            <TextInput source="message" resource="media" label="Title" fullWidth />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box flex={1} mr="1em" mt="1em">
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <Link to={'/media?filter={"type":"media"}'}><Button variant="outlined" color="default" style={{ marginLeft: '15px' }}>
                                    Cancel
                                </Button></Link>
                            </Box>

                        </Grid>

                    </Grid>
                </CardContent>
            </div>
        )}
        />
    )
};
const MediaEditActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={false} />
)
const EditMedia = (props) => {
    return (
        <Edit {...props}
            title="Edit media"
            actions={<MediaEditActions />}
        >
            <EditMediaForm />
        </Edit>
    )
};
export default EditMedia;