import * as React from "react";
import Switch from '@material-ui/core/Switch';
import {
    Edit,
    SelectInput,
    FormWithRedirect,
    TextInput,
    SaveButton,
    DeleteButton,
    Link,
    Create,
    SimpleForm,
    useNotify,
    useRedirect,
    Toolbar,
    useWarnWhenUnsavedChanges,
} from 'react-admin';
import { Box, Card, CardContent, Typography, Grid, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api_url from './ApiUrl';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { List, ListActions } from '@react-admin/ra-enterprise';


const useStyles = makeStyles({
    googleFa: { fontSize: '1rem', fontWeight: 500 }
});

const validateMediaData = (values) => {
    const errors = {};
    if (!values.media_logo) {
        errors.media_logo = 'The Logo is required';
    }

    if (!values.media_link) {
        errors.media_link = 'The media link is required';
    }

    if (!values.message) {
        errors.message = 'Message is required';
    }
    return errors
};

const AddMediaForm = (props) => {
    const classes = useStyles();
    //useWarnWhenUnsavedChanges(true);
    return (
        <div>
            <CardContent>
                <Grid container>

                    <Grid item xs={12}>
                        <Box flex={1} mr="1em">
                            <Grid container style={{ marginBottom: '10px' }}>
                                <Grid item xs={12} p="0">
                                    <Box flex={1} mr="1em">
                                        Add Media
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Box mr="1em">
                                        <TextInput source="media_logo" resource="media" label="Logo" fullWidth />
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box mr="1em">
                                        <TextInput source="media_link" resource="media" label="Link" fullWidth />
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box mr="1em">
                                        <TextInput source="message" resource="media" label="Title" fullWidth />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>

                </Grid>
            </CardContent>
        </div>
    )
};
const MediaAddActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={false} hasCreate={false} />
)
const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton
            label="Save"
            redirect="show"
            submitOnEnter={true}
            disabled={props.pristine}
        />
        <Link to={'/media?filter={"type":"media"}'}><Button variant="outlined" color="default" style={{ marginLeft: '15px' }}>Cancel</Button></Link>
    </Toolbar>
);
const AddMedia = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('Media added successfully');
        redirect('/media?filter={"type":"media"}');
    }
    const onFailure = (error) => {
        if (error.code == 429) {
            notify('Could not save:Invalid data provided', 'warning');
        } else {
            notify('ra.notification.http_error', 'warning')
        }
        redirect('/media?filter={"type":"media"}');
    }
    return (
        <Create
            {...props}
            actions={<MediaAddActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            title="Create media">
            <SimpleForm toolbar={<CustomToolbar />} validate={validateMediaData}>
                <AddMediaForm />
            </SimpleForm>
        </Create>
    )
};
export default AddMedia;