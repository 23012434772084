import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
    required,
    SelectInput
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const BrokerageBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('settings', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('settings', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const BrokerageListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('settings', 'export') == 'ok') ? true : false} />
)

const newStyle = makeStyles({
    tdcolor: { color: '#4f3cc9' }
});


const BrokerForm = props => (
    <RowForm {...props}>
        <TextField source="broker" />
        <TextInput source="coin_pairs" label="Currency pair" style={{width:'540px'}} />
        <TextField source="created" />
        <SelectInput source="status" choices={status_choices} label="Status" validate={required()} className="statusWidth" />
    </RowForm>
);
const status_choices = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' }];

const Brokerage = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'created', order: 'ASC' }}
            actions={<BrokerageListActions />}
            bulkActionButtons={<BrokerageBulkActionButtons />}
            pagination={<CustomPagination />}
        >
            <EditableDatagrid
                data-testid="store-datagrid"
                rowClick="edit"
                style={{ tableLayout: 'fixed',wordBreak:'break-word' }}
                //createForm={<BrokerForm />}
                className='brokersettings'
                editForm={<BrokerForm />}
            >
                <TextField source="broker" label="Broker" />
                <TextField source="coin_pairs" label="Currency pair" />
                <DateField source="created" label="Updated at" showTime />
                <BooleanField source="status" label="Status" />
            </EditableDatagrid>
        </List>
    )
};
export default Brokerage;