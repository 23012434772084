import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import AvatarField from './AvatarField';
import MuiAvatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    tdcolor: { color: (theme.palette.type === 'light') ? '#4f3cc9' : '#90caf9' }
}));


const WhitelistField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            <AvatarField
                className={classes.avatar}
                record={record}
                size={size}
            />
            <span className={classes.tdcolor}>{record.created_by}</span>
        </div>
    ) : null;
};

export default WhitelistField;
