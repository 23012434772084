import React, { FC } from 'react';

import SpentDateField from './SpentDateField';

const SpentDate = props =>
    props.record ? (
            <SpentDateField {...props} />
    ) : null;

    SpentDate.defaultProps = {
    source: 'spent_at',
    addLabel: true,
};

export default SpentDate;
