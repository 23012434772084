import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
    SelectInput
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const BasecurrencyBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('basecurrency', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('basecurrency', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const BasecurrencyListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('basecurrency', 'export') == 'ok') ? true : false} />
)

const BasecurrencyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Currency" source="full_name" defaultValue="" />
        <TextInput label="Symbol" source="short_name" defaultValue="" />
    </Filter>
);

const BaseCurrencyForm = props => (
    <RowForm {...props}>
        <TextField source="full_name" />
        <TextInput source="short_name" autoFocus label="Short name" />
        <TextInput source="range_from" label="Range from" />
        <TextInput source="range_to" label="Range to" />
        <TextInput source="reserve_amount" />
        <TextInput source="process_fee" label="Network/Process fee" />
        <SelectInput source="charge_type" choices={feeType_choices} label="Fee type"/>
    </RowForm>
);

const feeType_choices = [
    { id: 'percentage', name: 'Percentage' },
    { id: 'fixed', name: 'Fixed' }];

const newStyle = makeStyles({
    tdcolor: { color: '#4f3cc9' }
});

const BasecurrencyList = (props) => {
    //const classes = newStyle();
    //console.log(props.record);
    return (
        <List
            {...props}
            sort={{ field: 'full_name', order: 'ASC' }}
            actions={<BasecurrencyListActions />}
            filters={<BasecurrencyFilter />}
            bulkActionButtons={<BasecurrencyBulkActionButtons />}
            //pagination={<CustomPagination />}
            //perPage={25}
            title="Base currency"
        >
            <EditableDatagrid
                data-testid="store-datagrid"
                rowClick="edit"
                className="lastOrdersColumn"
                createForm={<BaseCurrencyForm />}
                editForm={<BaseCurrencyForm />}
            >
                <TextField source="full_name" label="Currencies" />
                <TextField source="short_name" label="Symbol" />
                <TextField source="range_from" label="Min amount" />
                <TextField source="range_to" label="Max amount" />
                <TextField source="reserve_amount" label="Reserved" />
                <TextField source="process_fee" label="Network/Process fee" />
                <DateField source="created" label="Updated at" showTime />
                <BooleanField source="status" label="Status" />
            </EditableDatagrid>
        </List>
    )
};
export default BasecurrencyList;