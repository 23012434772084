import * as React from "react";
import JWT from "jsonwebtoken";

import {
    Link,
    Show,
    useRedirect
} from 'react-admin';
import { Card, CardContent, Typography, Grid, InputLabel, Button, MenuItem, FormControl, Select } from '@material-ui/core';
import { makeStyles, darken, fade, lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import api_url from './ApiUrl';

const newStyle = makeStyles(theme => ({
    fontBold: { fontWeight: '400', textTransform: 'capitalize', fontSize: '15px', color: '#80a3b6' },
    marginTop15: { marginTop: '15px', marginBottom: '15px' },
    marginTop10: { marginTop: '10px' },
    marginTop20: { marginTop: '20px' },
    marginBottom20: { marginBottom: '20px' },
    paddingLeft15: { paddingLeft: '15px' },
    paddingRight15: { paddingRight: '15px' },
    rightAlignedCell: { textAlign: 'right' },
    marginBottom10: { marginBottom: '10px' },
    borderbottom0: { borderBottom: '0', textAlign: 'right' },
    statusIconSet: { display: 'flex', position: 'absolute', height: '18px', right: '375px' },
    hrline: { borderBottom: '1px solid rgba(128, 163, 182, 0.1)', marginBottom: '15px' },
    headerText: { fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, marginBottom: '0' },
    headerText2: { fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, marginBottom: '0', textAlign: 'right' },
    generalText: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 400 },
    generalText2: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 400, textAlign: 'right' },
    generalText3: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, textAlign: 'right', borderBottom: '0' },
    generalText4: { fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, textAlign: 'right', borderBottom: '0', textTransform: 'capitalize' },
    root: {
        width: 700,
        margin: '0 auto',
        borderStyle: 'solid',
        borderWidth: 1,
        marginTop: '2rem',
        marginBottom: '2rem',
        borderColor:
            theme.palette.type === 'light'
                ? lighten(fade(theme.palette.divider, 1), 0.88)
                : darken(fade(theme.palette.divider, 1), 0.68),
    },
    spacer: { height: 20 },
    invoices: { margin: '10px 0' },
}));


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
        marginLeft: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const OrderShowActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} />
)

const OrderDetailsForm = ({ record }) => {
    const redirect = useRedirect();
    const [status, setStatus] = React.useState('');
    let handleChange = (event) => {
        setStatus(event.target.value);
        let login_token = localStorage.getItem('auth');
        let new_status = event.target.value;
        let id = record.id;
        let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
            algorithm: "HS512",
        });
        const request = new Request(api_url() + '/changewithdrawstatus', {
            method: 'POST',
            body: JSON.stringify({ id, new_status }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${login_token}`,
                'xelxtoken': apitoken
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                } else {
                    if (new_status == 'expired') {
                        redirect('/canceledcommission/' + record.id + '/show');
                    }
                    if (new_status == 'completed') {
                        redirect('/completedcommission/' + record.id + '/show');
                    }

                }
            })
            .catch((error) => {
                throw new Error(error);
            });
    };
    const classes = newStyle();
    const selectList = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container className={classes.marginBottom20}>
                    <Grid item xs={6} className={classes.paddingLeft15}>
                        <Typography variant="h6" gutterBottom className={classes.headerText}>Recipient address</Typography>
                        <Typography className={classes.generalText} >{record.receiver_account}</Typography>

                        <Typography className={classes.marginTop10} ></Typography>
                        <Typography variant="h6" gutterBottom className={classes.headerText}>Withdrawal status</Typography>
                        <Typography className={classes.generalText} style={{ textTransform: 'capitalize' }}>{record.status}</Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.paddingRight15}>
                        <Typography variant="h6" gutterBottom align="right" className={classes.headerText}>Transaction ID</Typography>
                        <Typography className={classes.generalText} align="right" style={{ color: '#4f3cc9' }}>
                            <Link
                                to={`/pendingcommission?filter={"type":"pending"}`}
                            >{record.withdraw_id}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginBottom20}>
                    <Grid item xs={6} className={classes.paddingLeft15}>
                        <Typography variant="h6" gutterBottom className={classes.headerText}>Withdrawal created at</Typography>
                        <Typography className={classes.generalText} >{record.created_at}</Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <Paper elevation={2}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headerText}>Withdraw currency</TableCell>
                                        <TableCell className={classes.headerText}>Paid amount</TableCell>
                                        <TableCell className={classes.headerText2}>Received amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.generalText}>{record.withdraw_currency}</TableCell>
                                        <TableCell className={classes.generalText}>${record.withdraw_amount}</TableCell>
                                        <TableCell className={classes.generalText} align="right">${record.received_amount}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className={classes.generalText2} colSpan={2}>Withdraw fee{record.change_fee}</TableCell>
                                        <TableCell className={classes.generalText2}>${record.orginal_fee_total} {record.transfer_cost}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className={classes.generalText2} colSpan={2}>Transfer cost</TableCell>
                                        <TableCell className={classes.generalText2}>${record.transfer_cost}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className={classes.generalText3} colSpan={2}>Profits</TableCell>
                                        <TableCell className={classes.generalText3}>$0.00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} style={{ marginTop: '20px' }}>
                        <FormControl className={selectList.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Select status</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={status}
                                onChange={handleChange}
                                label="Select status"
                            >
                                <MenuItem value="expired">Canceled</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>


            </CardContent>
        </Card>
    )
};

const PendingDetails = props => {

    return (
        <Show {...props} actions={<OrderShowActions />} exporter={false}>
            <OrderDetailsForm />
        </Show>
    )
};
export default PendingDetails;
