import React, { FC } from 'react';

import RefUserField from './RefUserField';

const RefUserLink = props =>
    props.record ? (
            <RefUserField {...props} />
    ) : null;

    RefUserLink.defaultProps = {
    source: 'referee_name',
    addLabel: true,
};

export default RefUserLink;
