import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    displayLight: { display: theme.palette.type === 'light' ? 'block' : 'none' },
    displayDark: { display: theme.palette.type === 'light' ? 'none' : 'block' }
}));


const EmailStatusField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            <span className={classes.displayLight}>{(record.latest_news == true) ? <img src="../images/yes.svg" style={{ height: '18px' }} /> : <img src="../images/no.svg" style={{ height: '18px' }} />}</span>
            <span className={classes.displayDark}>{(record.latest_news == true) ? <img src="../images/yes-alter.svg" style={{ height: '18px' }} /> : <img src="../images/no-alter.svg" style={{ height: '18px' }} />}</span>
        </div>

    ) : null;
};

export default EmailStatusField;
