import React, { FC } from 'react';
import DaemonField from './DaemonField';

const DaemonStatus = props =>
    props.record ? (
        <DaemonField {...props} />
    ) : null;

DaemonStatus.defaultProps = {
    addLabel: true,
};

export default DaemonStatus;
