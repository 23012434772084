import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    BulkDeleteButton,
    BulkExportButton,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}
const MediaBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('media', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('media', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const MediaListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('media', 'export') == 'ok') ? true : false} />
)

const MediaList = (props) => {
    //const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'created', order: 'DESC' }}
            actions={<MediaListActions />}
            bulkActionButtons={<MediaBulkActionButtons />}
            pagination={<CustomPagination />}
            title="Media"            
        >
            <Datagrid rowClick="edit" className="lastOrdersColumn">
                <TextField source="media_logo" label="Logo" />
                <TextField source="media_link" label="Link" />
                <TextField source="message" label="Title" />
                <DateField source="created" showTime label="Create at" />
            </Datagrid>
        </List>
    )
};
export default MediaList;