import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Tooltip, Typography } from '@material-ui/core';
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const StatusField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {(record.status == 'completed') ?
                <Tooltip title="Completed"><CheckCircleOutlineIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(34, 154, 22)'}}
                /></Tooltip> : ((record.status == 'canceled') ? <Tooltip title="Canceled"><CancelOutlinedIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(183, 33, 54)'}}
                /></Tooltip>: <Tooltip title="Pending"><AvTimerOutlinedIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(12, 83, 183)'}}
                /></Tooltip>)}
        </div>
    ) : null;
};

export default StatusField;
