import * as React from 'react';

const Logo = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="40"
        viewBox="0 0 350 75"
        {...props}
    >
        <path
            fill="#808080"
            d="M147.061,52.713c-1.371,3.979-3.72,7.179-7.049,9.529s-6.983,3.524-11.031,3.591
    c-5.091,0.064-9.594-1.764-13.51-5.549c-3.917-3.852-5.94-8.356-6.005-13.642c-0.13-5.548,1.828-10.378,5.874-14.36
    c4.113-4.047,8.877-5.939,14.425-5.613c4.96,0.327,9.269,2.351,12.923,6.135c3.525,3.72,5.287,7.897,5.287,12.532v2.676h-33.418
    c0.391,3.721,1.958,6.854,4.764,9.267c2.807,2.417,6.07,3.656,9.856,3.591c5.875-0.064,10.052-2.806,12.598-8.158H147.061z
    M143.079,43.443c-0.718-3.458-2.35-6.265-4.895-8.419c-2.546-2.154-5.613-3.263-9.204-3.328c-3.59-0.066-6.722,1.044-9.398,3.198
    c-2.676,2.153-4.308,5.025-5.025,8.549H143.079z"
        ></path>
        <path
            fill="#808080"
            d="M157.374,64.919h-4.96V14.594h4.96V64.919z"
        ></path>
        <rect
            x="162.986"
            y="27.715"
            fill="#808080"
            width="4.896"
            height="37.204"
        ></rect>
        <rect
            x="162.986"
            y="14.594"
            fill="#1EB790"
            width="4.896"
            height="8.681"
        ></rect>
        <path
            fill="#808080"
            d="M173.232,45.989V14.594h4.896v18.798c3.656-4.242,8.224-6.396,13.708-6.593
    c5.352-0.195,9.986,1.633,13.837,5.548c3.916,3.917,5.874,8.552,5.874,13.968c0,5.222-1.827,9.725-5.417,13.512
    c-3.589,3.786-8.029,5.743-13.185,5.939c-5.354,0.196-9.987-1.697-13.968-5.613c-3.981-3.917-5.875-8.551-5.745-13.903V45.989z
    M202.604,56.237c2.677-2.937,3.981-6.332,3.917-10.313c-0.065-3.917-1.501-7.246-4.307-10.052
    c-2.742-2.806-6.07-4.243-10.051-4.243c-3.787,0-7.049,1.372-9.726,4.177c-2.676,2.807-4.111,6.135-4.177,10.051
    c-0.131,4.114,1.239,7.704,4.177,10.771c2.937,3.002,6.396,4.438,10.444,4.242C196.73,60.677,199.93,59.174,202.604,56.237z"
        ></path>
        <path
            fill="#808080"
            d="M220.685,64.919h-5.221V27.715h4.634v5.287c1.044-2.025,2.61-3.526,4.635-4.635
    c1.892-0.98,3.785-1.436,5.742-1.371v4.96c-2.284,0.065-4.177,0.718-5.612,1.893c-1.437,1.175-2.416,2.219-2.938,3.067
    c-0.848,1.437-1.24,3.329-1.24,5.744V64.919z"
        ></path>
        <path
            fill="#808080"
            d="M269.118,64.919h-4.83v-5.811c-4.047,4.635-9.008,6.854-14.881,6.658c-5.157-0.196-9.53-2.154-13.12-5.939
    c-3.589-3.787-5.418-8.29-5.418-13.512c0-5.416,1.958-10.116,5.81-13.968c3.85-3.916,8.485-5.743,13.903-5.548
    c5.222,0.197,9.66,2.154,13.315,6.005c3.655,3.785,5.417,8.29,5.222,13.446v0.261V64.919z M235.895,45.924
    c-0.065,3.916,1.175,7.375,3.85,10.313c2.677,2.937,5.941,4.44,9.728,4.634c4.111,0.196,7.571-1.24,10.442-4.242
    c2.938-3.067,4.308-6.657,4.243-10.771c-0.065-3.916-1.501-7.244-4.176-10.051c-2.677-2.806-5.94-4.177-9.727-4.177
    c-3.981,0-7.311,1.372-10.117,4.177C237.331,38.614,235.96,41.942,235.895,45.924z"
        ></path>
        <path
            fill="#1EB790"
            d="M93.478,24.685c-3.691-8.259-10.21-14.78-18.539-18.489c-8.948-3.988-18.916-4.251-28.063-0.744
    c-9.145,3.51-16.378,10.37-20.365,19.32c-1.037,2.327-1.819,4.722-2.355,7.154c0.173,0.075,0.345,0.151,0.513,0.226
    c3.145,1.371,6.319,2.594,9.445,3.647c1.372-9.015,7.413-17.07,16.517-20.564c11.251-4.313,23.684-0.32,30.497,8.954
    c1,1.354,1.873,2.818,2.607,4.39c-3.287,1.671-23.66,11.425-46.365,8.101c0,0,22.478,7.818,48.375-1.919
    c3.201-1.204,6.454-2.677,9.72-4.465c-0.01-0.047-0.022-0.092-0.034-0.138c-0.328-1.208-0.718-2.411-1.175-3.597
    C94.015,25.926,93.754,25.302,93.478,24.685z"
        ></path>
        <path
            fill="#1EB790"
            d="M23.326,38.978c-0.018,0.941,0.002,1.88,0.058,2.818c3.194,1.86,6.533,3.552,9.944,5.035
    c0.563,0.241,1.125,0.48,1.692,0.715c-0.304-0.968-0.55-1.938-0.737-2.913C30.191,42.854,26.538,40.918,23.326,38.978z"
        ></path>
        <path
            fill="#808080"
            d="M88.033,39.336L88.033,39.336c-0.612,0.233-1.217,0.454-1.823,0.67c-0.712,0.252-1.424,0.497-2.13,0.726
    c-10.294,2.815-26.78,4.705-47.778-1.714c-0.808-0.245-1.623-0.509-2.444-0.78c-3.277-1.083-6.662-2.372-10.144-3.89
    C18.047,31.876,12.119,28.8,5.955,25c0,0,5.835,7.014,17.371,13.979c3.212,1.94,6.866,3.876,10.957,5.656
    c4.543,1.975,9.625,3.756,15.243,5.139c2.095,0.513,4.261,0.973,6.506,1.366c9.319,2.33,19.79,1.94,26.91,1.203
    c0.477-0.048,0.938-0.098,1.38-0.148c2.333-0.214,4.714-0.504,7.146-0.869c1.161-0.178,2.337-0.371,3.524-0.584
    c1.431-4.507,1.972-9.198,1.607-13.854c-0.036-0.454-0.076-0.906-0.131-1.359C93.723,36.96,90.896,38.238,88.033,39.336z"
        ></path>
        <path
            fill="#1EB790"
            d="M93.376,54.722l-1.146,0.532c-3.021,0.829-10.814,1.603-10.814,1.603l0,0
    c-0.564,0.05-1.139,0.094-1.732,0.136c-9.13,0.643-21.558,0.394-31.535-3.924c0,0,12.941,7.407,25.17,9.195
    c-1.231,0.729-2.542,1.365-3.922,1.893c-1.078,0.414-2.166,0.751-3.261,1.015c-12.591,3.035-25.822-3.683-30.583-16.091
    c-0.194-0.507-0.371-1.023-0.534-1.534c-0.567-0.235-1.129-0.474-1.692-0.715c-3.412-1.483-6.75-3.175-9.944-5.035
    c0.215,3.731,1.009,7.449,2.387,11.037c3.508,9.15,10.369,16.381,19.318,20.368c8.948,3.983,18.915,4.249,28.062,0.741
    c6.449-2.473,11.945-6.616,16.023-11.98c0.379-0.5,0.747-1.01,1.1-1.529l0,0c0,0,0.013-0.018,0.032-0.051
    c0.035-0.048,0.071-0.1,0.105-0.149c0.588-0.874,2.904-4.389,2.988-5.521C93.505,54.463,93.478,54.512,93.376,54.722z"
        ></path>
        <path
            fill="#1EB790"
            d="M302.098,24.758h9.955L300.19,41.859c-2.273-2.899-2.482-3.031-4.725-5.538L302.098,24.758z"
        ></path>
        <path
            fill="#1EB790"
            d="M312.24,64.426c0,0-3.562-5.957-9.449-13.772L312.24,64.426z"
        ></path>
        <path
            fill="#1EB790"
            d="M251.025,14.018c-9.888,0.945-15.12,7.336-15.12,7.336c16.875-10.031,36.033,3.172,48.717,18.068
    l2.982,4.237l-2.514,3.669l-11.936,17.098h8.931l5.861-8.262l5.067-7.721l11.229,15.983h7.999l-9.449-13.772
    C291.328,35.435,271.041,13.153,251.025,14.018z"
        ></path>
    </svg>
);

export default Logo;
