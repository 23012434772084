import * as React from 'react';
import { useState, useEffect } from "react";
import { Fragment } from 'react';
import {
    Datagrid,
    TextField,
    Filter,
    ImageField,
    DateInput,
    SelectInput,
    BulkExportButton,
    BulkDeleteButton,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import JWT from "jsonwebtoken";
import api_url from "./ApiUrl";
import CustomerLinkFieldEvents from './CustomerLinkFieldEvents';
import { Tooltip, Typography } from '@material-ui/core';
import CustomCountryField from './CustomCountryField';
import CustomDeviceField from './CustomDeviceField';
import CustomOSField from './CustomOSField';
import CustomBrowserField from './CustomBrowserField';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    } else {
        let allpermission = JSON.parse(localStorage.getItem("roles"));
        let roledata = [];
        if (login_role == 'accountant') {
            roledata = allpermission.accountant;
        }
        if (login_role == 'auditor') {
            roledata = allpermission.auditor;
        }
        if (login_role == 'editor') {
            roledata = allpermission.editor;
        }
        roledata.map((role_row, index) => {
            if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
                return_value = 'ok';
            }
        });
        return return_value;
    }
}

const PostBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('events', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('events', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);


const EventFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Resource" source="groups" choices={props.rlist} />
        <DateInput label="Created since" source="created_at" defaultValue="" />
        <DateInput label="Creted before" source="updated_at" defaultValue="" />
    </Filter>
);

const EventList = (props) => {
    const [resourceList, setResourceList] = useState({});
    useEffect(() => {
        getCountryLogWithFetch();
    }, []);

    const getCountryLogWithFetch = async () => {
        let login_token = localStorage.getItem("auth");
        let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
            algorithm: "HS512",
        });
        const request = new Request(api_url() + "/get_resources", {
            method: "POST",
            body: JSON.stringify({ role_type: "resource" }),
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${login_token}`,
                'xelxtoken': apitoken
            }),
        });
        const response = await fetch(request);
        const resourceJsonData = await response.json();
        setResourceList(resourceJsonData);
    };
    return (
        <List
            {...props}
            sort={{ field: 'date', order: 'desc' }}
            actions={
                <ListActions
                    breadcrumb={<CustomBreadcrumb variant="actions" />}
                    exporter={(idRoleAllow('events', 'export') == 'ok') ? true : false}
                />
            }
            filters={<EventFilter rlist={resourceList} />}
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<CustomPagination />}
            title="Events"
        >
            <Datagrid rowClick="show" className="segmentColumnWidth">
                <TextField source="date" label="Date" />
                <CustomCountryField source="country" className='tableimages' />
                <TextField source="ip" label="IP" />
                <CustomDeviceField source='device' label="Device" className='tableimages' />
                <CustomOSField source="os" label="OS" className='tableimages' />
                <CustomBrowserField source="browser" label="Browser" className='tableimages' />
                <TextField source="resource" label="Resource" />
                <CustomerLinkFieldEvents source="author" label="Author" />
                <TextField source="action" label="Action" />
            </Datagrid>
        </List>
    )
};
export default EventList;