import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    EmailField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            //console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const DepositBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('deposits', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('deposits', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const DepositListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('deposits', 'export') == 'ok') ? true : false} />
)

const DepositFilter = (props) => (
    <Filter {...props}>
        <TextInput label="User Id" source="user_id" defaultValue="" />
        <TextInput label="Address" source="address" defaultValue="" />        
    </Filter>
);

const newStyle = makeStyles({
    tdcolor: { color: '#4f3cc9' }
});

const DepositList = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'user_id', order: 'ASC' }}
            actions={<DepositListActions />}
            filters={<DepositFilter />}
            bulkActionButtons={<DepositBulkActionButtons />}
            pagination={<CustomPagination />}            
        >
            <Datagrid className="lastOrdersColumn">
                <TextField source="user_id" label="User Id" />
                <TextField source="address" label="Crypto address" />
                <DateField source="created" label="Created at" showTime/>
                <TextField source="amount" label="Amount" />
                <TextField source="currency" label="Currency" />
                <TextField source="min_confirm" label="Confirmation" />
                <TextField source="status" label="Status"/>
            </Datagrid>
        </List>
    )
};
export default DepositList;