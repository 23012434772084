import React, { FC } from 'react';
import { Link } from 'react-admin';

import WhitelistField from './WhitelistField';

const CustomLinkField = props =>
    props.record ? (
            <WhitelistField {...props} />
    ) : null;

    CustomLinkField.defaultProps = {
    source: 'created_by',
    addLabel: true,
};

export default CustomLinkField;
