import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@material-ui/icons/TrendingDownOutlined";
import { Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import WifiOffIcon from '@material-ui/icons/WifiOff'; 
import WifiIcon from '@material-ui/icons/Wifi';

import {
    ImageField,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    iconColor: { color: (theme.palette.type === 'light') ? 'rgb(128, 128, 128)' : 'rgb(255,255,255)' },
    iconColorUnblocked: { color: (theme.palette.type === 'light') ? 'rgb(30, 183, 144)' : 'rgb(30, 183, 144)' }
}));


const BlockStatusField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            <Tooltip title={record.block_status}>
                {(record.block_status == 'Blocked') ? <WifiOffIcon className={classes.iconColor} fontSize="small"/> : <WifiIcon className={classes.iconColorUnblocked} fontSize="small"/>}
            </Tooltip>
        </div>
    ) : null;
};

export default BlockStatusField;
