import React, { FC } from 'react';
import { Link } from 'react-admin';

import WStatusField from './WStatusField';

const WithdrawStatus = props =>
    props.record ? (
            <WStatusField {...props} />
    ) : null;

    WithdrawStatus.defaultProps = {
    source: 'status',
    addLabel: true,
};

export default WithdrawStatus;
