import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    TextField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    // ReferenceField
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import OrderStatus from './OrderStatus';
import UserLinkOrder from './UserLinkOrder';
import OdLink from './OdLink';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}
const PostBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('orders', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('orders', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const OrderListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('orders', 'export') == 'ok') ? true : false} />
)

const OrdersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Transaction Id" source="transaction_id" defaultValue="" />
        <TextInput label="User Id" source="user_id" defaultValue="" />
        <TextInput label="Markets" source="markets" defaultValue="" />
        <DateInput label="Created since" source="created_at" defaultValue="" />
        <DateInput label="Created before" source="updated_at" defaultValue="" />
    </Filter>
);

const newStyle = makeStyles((theme)=>({
    tdcolor: { color: (theme.palette.type === 'light') ? '#4f3cc9' : '#90caf9' }
}));

const OrderList = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'created_at', order: 'DESC' }}
            actions={<OrderListActions />}
            filters={<OrdersFilter />}
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<CustomPagination />}
        >
            <Datagrid className="lastOrdersColumn">
                <OdLink source="transaction_id" label="Transaction Id" className={classes.tdcolor} />
                <UserLinkOrder source="user_id" label="User Id" />
                <TextField source="markets" />
                <TextField source="side" className={classes.tdcolor} />
                <TextField source="send_amount" label="Amount" />
                <TextField source="received_amount" label="Executed" />
                <TextField source="xml_rate" label="Price" />
                <DateField source="created_at" showTime />
                <OrderStatus source="status" label="Status" />
                {/* <ReferenceField label="Author" source="transaction_id" reference="order" link="show" /> */}
            </Datagrid>
        </List>
    )
};
export default OrderList;