import React, { FC } from 'react';

import UserLinkFieldOrder from './UserLinkFieldOrder';

const UserLinkOrder = props =>
    props.record ? (
            <UserLinkFieldOrder {...props} />
    ) : null;

    UserLinkOrder.defaultProps = {
    source: 'user_id_raw',
    addLabel: true,
};

export default UserLinkOrder;
