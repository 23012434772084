import React, { FC } from 'react';
import OSField from './OSField';

const CustomOSField = props =>
    props.record ? (
            <OSField {...props} />
    ) : null;

    CustomOSField.defaultProps = {
    source: 'os',
    addLabel: true,
};

export default CustomOSField;
