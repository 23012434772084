import React, { FC } from 'react';
import { Link } from 'react-admin';

import CountryField from './CountryField';

const CustomCountryField = props =>
    props.record ? (
            <CountryField {...props} />
    ) : null;

    CustomCountryField.defaultProps = {
    source: 'country',
    addLabel: true,
};

export default CustomCountryField;
