import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const FlagsField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            <Tooltip title={record.country}>
                <img src={record.flags} height='16'/>
            </Tooltip>
        </div>
    ) : null;
};

export default FlagsField;
