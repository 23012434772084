import React, { FC, ReactNode, useState, useEffect, ReactElement } from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import OrderIcon from "@material-ui/icons/History";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import PeopleIcon from "@material-ui/icons/People";
import BlockIcon from "@material-ui/icons/Block";
import MoneyOffOutlinedIcon from "@material-ui/icons/MoneyOffOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import SettingsBackupRestoreOutlinedIcon from "@material-ui/icons/SettingsBackupRestoreOutlined";
import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import SettingsEthernetOutlinedIcon from "@material-ui/icons/SettingsEthernetOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import FormatListNumberedOutlinedIcon from "@material-ui/icons/FormatListNumberedOutlined";
import VerticalAlignBottomOutlinedIcon from "@material-ui/icons/VerticalAlignBottomOutlined";
import VerticalAlignTopOutlinedIcon from "@material-ui/icons/VerticalAlignTopOutlined";
import RepeatOutlinedIcon from "@material-ui/icons/RepeatOutlined";
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import ShuffleOutlinedIcon from "@material-ui/icons/ShuffleOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
//import CollectionsBookmarkOutlinedIcon from "@material-ui/icons/CollectionsBookmarkOutlined";
import BlurCircularOutlinedIcon from "@material-ui/icons/BlurCircularOutlined";
import AcUnitOutlinedIcon from "@material-ui/icons/AcUnitOutlined";
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import TrendingDownOutlinedIcon from "@material-ui/icons/TrendingDownOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import VpnLockOutlinedIcon from '@material-ui/icons/VpnLockOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ExploreOffOutlinedIcon from '@material-ui/icons/ExploreOffOutlined';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import MultilineChartOutlinedIcon from '@material-ui/icons/MultilineChartOutlined';

import {
  Menu,
  MenuItem,
  MultiLevelMenu,
  MenuItemCategory,
} from "@react-admin/ra-navigation";
//import { Menu } from "@react-admin/ra-rbac";


import { CardContent, Typography } from "@material-ui/core";

const checkLockClick = (status) => {
  if (status == "lock") {
    localStorage.setItem("clickedMenu", "locked");
  } else if (status == 'unlock') {
    localStorage.setItem("clickedMenu", "unlocked");
  } else if (status == "countryset") {
    localStorage.setItem("clickedMenu", "countryset");
  } else if (status == "ipwhitelist") {
    localStorage.setItem("clickedMenu", "ipwhitelist");
  } else if (
    status == "editor" ||
    status == "accountant" ||
    status == "auditor"
  ) {
    localStorage.setItem("clickedMenu", "rolesetting");
  } else {
    localStorage.setItem("clickedMenu", "blocked");
  }
};

const checkdepositWithdrawClick = (menu_type) => {
  if (menu_type == "deposits" || menu_type == "withdraws") {
    localStorage.setItem("clickedMenuFinance", menu_type);
  } else if (
    menu_type == "allreviews" ||
    menu_type == "pendingreviews" ||
    menu_type == "badreviews"
  ) {
    localStorage.setItem("clickedMenuReviews", menu_type);
  } else if (
    menu_type == "basecurrency" ||
    menu_type == "cryptocurrency" ||
    menu_type == "ecurrencies" ||
    menu_type == "fiatcurrencies" ||
    menu_type == "pricestore" ||
    menu_type == "brokerage"
  ) {
    localStorage.setItem("clickedMenuSettings", menu_type);
  } else if (
    menu_type == "exchangepair" ||
    menu_type == "buypair" ||
    menu_type == "sellpair"
  ) {
    localStorage.setItem("clickedMenuMerkets", menu_type);
  } else if (
    menu_type == "earncommission" ||
    menu_type == "pending" ||
    menu_type == "completed" ||
    menu_type == "canceled" ||
    menu_type == "referralcommission" ||
    menu_type == "withdrawfees"
  ) {
    localStorage.setItem("clickedMenuReferral", menu_type);
  } else if (
    menu_type == "media" ||
    menu_type == "news" ||
    menu_type == "campaign"
  ) {
    localStorage.setItem("clickedTools", menu_type);
  } else if (menu_type == "events") {
    localStorage.setItem("clickedEvents", menu_type);
  } else {
    localStorage.setItem("clickedMenuSettings", menu_type);
  }
};

const newStyle = makeStyles((theme) => ({
  submenu: { fontSize: "0.75rem !important" },
  RaMenuItem: {
    root: {
      color: '#808080',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#000',
      },
    },
  },
}));

function idRoleAllow(resource_name, action_name) {
  let return_value = "no";
  let login_role = localStorage.getItem("role");
  if (login_role == "admin") {
    return "ok";
  }
  let allpermission = JSON.parse(localStorage.getItem("roles"));
  let roledata = [];
  if (login_role == "accountant") {
    roledata = allpermission.accountant;
  }
  if (login_role == "auditor") {
    roledata = allpermission.auditor;
  }
  if (login_role == "editor") {
    roledata = allpermission.editor;
  }
  roledata.map((role_row, index) => {
    if (
      role_row.resource == resource_name &&
      role_row.action.indexOf(action_name) > -1
    ) {
      console.log("operation ok");
      return_value = "ok";
    }
  });
  return return_value;
}

const MyMenu = ({ record }) => {
  const classes = newStyle();
  return (
    <MultiLevelMenu variant="categories">
      <MenuItemCategory
        name="dashboard"
        to={"/"}
        exact
        label="Dashboard"
        icon={<DashboardIcon />}
      />

      <MenuItemCategory
        name="users"
        to={"/users?filter={}"}
        label="Users"
        exact
        icon={<PeopleIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Users
          </Typography>
          <Menu className={classes.RaMenuItem}>
            <MenuItem
              name="active"
              className={classes.RaMenuItem}
              to={'/users?filter={"type":"active"}'}
              label="Active users"
              icon={<CheckCircleOutlineIcon />}
            />
            <MenuItem
              name="pending"
              to={'/users?filter={"type":"pending"}'}
              label="Pending users"
              icon={<AvTimerIcon />}
            />
            <MenuItem
              name="bannedusers"
              to={'/users?filter={"type":"banned"}'}
              label="Banned users"
              icon={<BlockIcon />}
            />
          </Menu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        label="Security"
        name={
          localStorage.getItem("clickedMenu") == "blocked"
            ? "blocked"
            : localStorage.getItem("clickedMenu") == "locked"
              ? "locked"
              : localStorage.getItem("clickedMenu") == "unlocked"
                ? "unlocked"
                : localStorage.getItem("clickedMenu") == "countryset"
                  ? "countrysettings"
                  : localStorage.getItem("clickedMenu") == "ipwhitelist"
                    ? 'ipwhitelist' : "rolesetting"
        }
        to={"/locked?filter={}"}
        icon={<SecurityOutlinedIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Security
          </Typography>
          <MultiLevelMenu>
            <MenuItem
              to=""
              name="restricteduser"
              label="Restricted users"
              icon={<ExploreOffOutlinedIcon />}
            >
              <MenuItem
                name="lock"
                to={'/locked?filter={"type":"lock"}'}
                label="Locked users"
                onClick={() => checkLockClick("lock")}
                icon={<LockOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                name="unlock"
                to={'/unlocked?filter={"type":"unlock"}'}
                label="Unlocked users"
                onClick={() => checkLockClick("unlock")}
                icon={<LockOpenOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                name="block"
                to={'/blocked?filter={"type":"block"}'}
                label="Blocked guest"
                onClick={() => checkLockClick("block")}
                icon={<NotInterestedOutlinedIcon fontSize="small" />}
              />
            </MenuItem>

            {localStorage.getItem("role") == "admin" ? (
              <MenuItem
                to=""
                name="rolebaseaccess"
                label="RBA control"
                icon={<SecurityOutlinedIcon />}
              >
                <MenuItem
                  name="editor"
                  to={'/rolesettingeditor?filter={"type":"editor"}'}
                  onClick={() => checkLockClick("editor")}
                  label="Editor"
                  icon={<EditOutlinedIcon fontSize="small" />}
                />
                <MenuItem
                  name="accountant"
                  to={'/rolesettingaccountant?filter={"type":"accountant"}'}
                  onClick={() => checkLockClick("accountant")}
                  label="Accountant"
                  icon={<SupervisorAccountOutlinedIcon fontSize="small" />}
                />
                <MenuItem
                  name="auditor"
                  to={'/rolesettingauditor?filter={"type":"auditor"}'}
                  label="Auditor"
                  onClick={() => checkLockClick("auditor")}
                  icon={<PolicyOutlinedIcon fontSize="small" />}
                />
              </MenuItem>
            ) : (
              ""
            )}

            <MenuItem
              to={'/countrysettings?filter={"type":"all"}'}
              label="Global restrictions"
              onClick={() => checkLockClick("countryset")}
              icon={<LanguageOutlinedIcon />}
            />

            <MenuItem
              to={'/ipwhitelist?filter={"type":"all"}'}
              label="IP whitelist"
              onClick={() => checkLockClick('ipwhitelist')}
              icon={<VpnLockOutlinedIcon />}
            />

          </MultiLevelMenu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        name="orders"
        to={"/orders?filter={}"}
        exact
        label="Orders"
        icon={<OrderIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Orders
          </Typography>
          <MultiLevelMenu>
            <MenuItem
              name="pendingorder"
              to={"#"}
              label="Pending orders"
              icon={<AvTimerIcon />}
            >
              <MenuItem
                name="waiting"
                to={'/orders?filter={"type":"waiting"}'}
                label="Waiting"
                icon={<MoreHorizOutlinedIcon fontSize="small" />}
              />

              <MenuItem
                name="processing"
                to={'/orders?filter={"type":"processing"}'}
                label="Processing"
                icon={<SyncOutlinedIcon fontSize="small" />}
              />

              <MenuItem
                name="onhold"
                to={'/orders?filter={"type":"hold"}'}
                label="Hold"
                icon={<MoneyOffOutlinedIcon fontSize="small" />}
              />
            </MenuItem>

            <MenuItem
              name="refundorder"
              to={'/orders?filter={"type":"refund"}'}
              label="Refunded orders"
              icon={<SettingsBackupRestoreOutlinedIcon />}
            />
            <MenuItem
              name="completedorder"
              to={'/orders?filter={"type":"completed"}'}
              label="Completed orders"
              icon={<CheckCircleOutlineIcon />}
            />
            <MenuItem
              name="expireorder"
              to={'/orders?filter={"type":"expired"}'}
              label="Expired orders"
              icon={<UpdateOutlinedIcon />}
            />
          </MultiLevelMenu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        label="Finance"
        name={
          localStorage.getItem("clickedMenuFinance")
            ? localStorage.getItem("clickedMenuFinance")
            : "financemenu"
        }
        to={"/deposits?filter={}"}
        exact
        icon={<AccountBalanceOutlinedIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Finance
          </Typography>
          <MultiLevelMenu>
            <MenuItem
              name="depositbitcoin"
              to={"#"}
              label="Deposit crypto"
              icon={<SaveAltOutlinedIcon />}
            >
              <MenuItem
                to={'/depositbitcoin?filter={"type":"bitcoin"}'}
                label="Bitcoin"
                onClick={() => checkdepositWithdrawClick("bitcoin")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />

              <MenuItem
                to={'/depositethereum?filter={"type":"ethereum"}'}
                label="Ethereum"
                onClick={() => checkdepositWithdrawClick("ethereum")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />

              <MenuItem
                to={'/depositusdterc20?filter={"type":"USDT-ERC20"}'}
                label="USDT ERC20"
                onClick={() => checkdepositWithdrawClick("USDT-ERC20")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />

              <MenuItem
                to={'/depositusdttrc20?filter={"type":"USDT-TRC20"}'}
                label="USDT TRC20"
                onClick={() => checkdepositWithdrawClick("USDT-TRC20")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/depositusdcerc20?filter={"type":"USDC-ERC20"}'}
                label="USDC ERC20"
                onClick={() => checkdepositWithdrawClick("USDC-ERC20")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/depositusdctrc20?filter={"type":"USDC-TRC20"}'}
                label="USDC TRC20"
                onClick={() => checkdepositWithdrawClick("USDC-TRC20")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />
            </MenuItem>

            <MenuItem
              name="depositecurrency"
              to={"#"}
              label="Deposit ecurrency"
              icon={<SaveAltOutlinedIcon />}
            >
              <MenuItem
                to={'/depositpmusd?filter={"type":"PMUSD"}'}
                label="Perfect money"
                onClick={() => checkdepositWithdrawClick("PMUSD")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/depositfpusd?filter={"type":"FPUSD"}'}
                label="Fasapay"
                onClick={() => checkdepositWithdrawClick("FPUSD")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/depositadvcusd?filter={"type":"ADVCUSD"}'}
                label="Advcash"
                onClick={() => checkdepositWithdrawClick("ADVCUSD")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/depositpayeer?filter={"type":"PAYEER"}'}
                label="Payeer"
                onClick={() => checkdepositWithdrawClick("PAYEER")}
                icon={<KeyboardArrowRightOutlinedIcon fontSize="small" />}
              />
            </MenuItem>

            <MenuItem
              to={'/deposits?filter={"type":"deposit"}'}
              label="Deposit history"
              onClick={() => checkdepositWithdrawClick("deposits")}
              icon={<AccountBalanceWalletOutlinedIcon />}
            />
            <MenuItem
              to={'/withdraws?filter={"type":"withdraw"}'}
              label="Withdraw history"
              onClick={() => checkdepositWithdrawClick("withdraws")}
              icon={<OpenInBrowserOutlinedIcon />}
            />
          </MultiLevelMenu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        label="Settings"
        name={
          localStorage.getItem("clickedMenuSettings")
            ? localStorage.getItem("clickedMenuSettings")
            : "basecurrencysettings"
        }
        to={"/settings?filter={}"}
        exact
        icon={<SettingsEthernetOutlinedIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Settings
          </Typography>
          <Menu>

            <MenuItem
              to={'/cryptocurrency?filter={"type":"cryptocurrency"}'}
              label="Cryptocurrencies"
              onClick={() => checkdepositWithdrawClick("cryptocurrency")}
              icon={<BlurCircularOutlinedIcon />}
            />

            <MenuItem
              to={'/ecurrencies?filter={"type":"ecurrencies"}'}
              label="E-currencies"
              onClick={() => checkdepositWithdrawClick("ecurrencies")}
              icon={<AcUnitOutlinedIcon />}
            />
            <MenuItem
              to={'/fiatcurrencies?filter={"type":"fiatcurrencies"}'}
              label="Fiat currencies"
              onClick={() => checkdepositWithdrawClick("fiatcurrencies")}
              icon={<MonetizationOnOutlinedIcon />}
            />

            <MenuItem
              to={'/pricestore?filter={"type":"pricestore"}'}
              label="Exchange rate"
              onClick={() => checkdepositWithdrawClick("pricestore")}
              icon={<SwapHorizOutlinedIcon />}
            />

            <MenuItem
              to={'/sellrate?filter={"type":"sellrate"}'}
              label="Sell rate"
              onClick={() => checkdepositWithdrawClick("sellrate")}
              icon={<MultilineChartOutlinedIcon />}
            />

            <MenuItem
              to={'/buyrate?filter={"type":"buyrate"}'}
              label="Buy rate"
              onClick={() => checkdepositWithdrawClick("buyrate")}
              icon={<ShoppingBasketOutlinedIcon />}
            />

            <MenuItem
              to={'/brokerage?filter={"type":"brokerage"}'}
              label="Brokerage"
              onClick={() => checkdepositWithdrawClick("brokerage")}
              icon={<ShuffleOutlinedIcon />}
            />
          </Menu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        label="Markets"
        exact
        name={
          localStorage.getItem("clickedMenuMerkets")
            ? localStorage.getItem("clickedMenuMerkets")
            : "markets"
        }
        to={"/markets?filter={}"}
        icon={<HomeOutlinedIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Markets
          </Typography>
          <Menu>
            <MenuItem
              to={'/exchangepair?filter={"type":"exchangepair"}'}
              label="Exchange pair"
              onClick={() => checkdepositWithdrawClick("exchangepair")}
              icon={<RepeatOutlinedIcon />}
            />
            <MenuItem
              to={'/buypair?filter={"type":"buypair"}'}
              label="Buy pair"
              onClick={() => checkdepositWithdrawClick("buypair")}
              icon={<VerticalAlignBottomOutlinedIcon />}
            />
            <MenuItem
              to={'/sellpair?filter={"type":"sellpair"}'}
              label="Sell pair"
              onClick={() => checkdepositWithdrawClick("sellpair")}
              icon={<VerticalAlignTopOutlinedIcon />}
            />
          </Menu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        label="Referrals"
        exact
        name={
          localStorage.getItem("clickedMenuReferral")
            ? localStorage.getItem("clickedMenuReferral")
            : "referrals"
        }
        to={"/referrals?filter={}"}
        icon={<ShareOutlinedIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Referrals
          </Typography>
          <MultiLevelMenu>
            <MenuItem
              to={""}
              label="Commission settings"
              name="commission_settings"
              icon={<SettingsOutlinedIcon />}
            >
              <MenuItem
                to={'/referralcommission?filter={"type":"referralcommission"}'}
                label="Referral commission"
                onClick={() => checkdepositWithdrawClick("referralcommission")}
                icon={<AttachMoneyOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/withdrawfees?filter={"type":"withdrawfees"}'}
                label="Withdraw fees"
                onClick={() => checkdepositWithdrawClick("withdrawfees")}
                icon={<TrendingDownOutlinedIcon fontSize="small" />}
              />
            </MenuItem>
            <MenuItem
              to={'/earncommission?filter={"type":"earncommission"}'}
              label="Earned commission"
              onClick={() => checkdepositWithdrawClick("earncommission")}
              icon={<TrendingUpOutlinedIcon />}
            />
            <MenuItem
              to={""}
              label="Withdraw commission"
              name="withdrawalcommission"
              icon={<OpenInBrowserOutlinedIcon />}
            >
              <MenuItem
                to={'/pendingcommission?filter={"type":"pending"}'}
                label="Pending"
                onClick={() => checkdepositWithdrawClick("pending")}
                icon={<AvTimerOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/completedcommission?filter={"type":"completed"}'}
                label="Completed"
                onClick={() => checkdepositWithdrawClick("completed")}
                icon={<CheckCircleOutlinedIcon fontSize="small" />}
              />
              <MenuItem
                to={'/canceledcommission?filter={"type":"canceled"}'}
                label="Canceled"
                onClick={() => checkdepositWithdrawClick("canceled")}
                icon={<CancelOutlinedIcon fontSize="small" />}
              />
            </MenuItem>
          </MultiLevelMenu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        label="Tools"
        exact
        name={
          localStorage.getItem("clickedTools")
            ? localStorage.getItem("clickedTools")
            : "termsofuse"
        }
        to={"/tools?filter={}"}
        icon={<BuildOutlinedIcon />}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Tools
          </Typography>
          <Menu>
            {idRoleAllow("media", "list") == "ok" ? (
              <MenuItem
                to={'/media?filter={"type":"media"}'}
                label="Media"
                onClick={() => checkdepositWithdrawClick("media")}
                icon={<PermMediaOutlinedIcon />}
              />
            ) : (
              ""
            )}
            {idRoleAllow("news", "list") == "ok" ? (
              <MenuItem
                to={'/news?filter={"type":"news"}'}
                label="News"
                onClick={() => checkdepositWithdrawClick("news")}
                icon={<VolumeUpOutlinedIcon />}
              />
            ) : (
              ""
            )}
            {idRoleAllow("campaign", "list") == "ok" ? (
              <MenuItem
                to={'/campaign?filter={"type":"campaign"}'}
                label="Campaign"
                onClick={() => checkdepositWithdrawClick("campaign")}
                icon={<MailOutlinedIcon />}
              />
            ) : (
              ""
            )}
          </Menu>
        </CardContent>
      </MenuItemCategory>

      <MenuItemCategory
        label="Events"
        exact
        name={
          localStorage.getItem("clickedEvents")
            ? localStorage.getItem("clickedEvents")
            : "events"
        }
        to={'/events?filter={"type":"events"}'}
        onClick={() => checkdepositWithdrawClick("events")}
        icon={<FormatListNumberedOutlinedIcon />}
      ></MenuItemCategory>

      <MenuItemCategory
        exact
        name={
          localStorage.getItem("clickedMenuReviews")
            ? localStorage.getItem("clickedMenuReviews")
            : "reviews"
        }
        icon={<FormatListNumberedOutlinedIcon />}
        label={"Reviews"}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Reviews
          </Typography>
          <Menu>
            <MenuItem
              to={"/reviews?filter={}"}
              icon={<CheckCircleOutlineIcon />}
              onClick={checkdepositWithdrawClick("allreviews")}
              label="All Reviews"
            />

            <MenuItem
              to={"#"}
              icon={<CheckCircleOutlineIcon />}
              onClick={checkdepositWithdrawClick("pendingreviews")}
              label="Pending Reviews"
            />

            <MenuItem
              to={"#"}
              icon={<CheckCircleOutlineIcon />}
              onClick={checkdepositWithdrawClick("badreviews")}
              label="Bad Reviews"
            />
          </Menu>
        </CardContent>
      </MenuItemCategory>
    </MultiLevelMenu>
  );
};
export default MyMenu;
